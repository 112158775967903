import type { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import type { Router } from "@frui.ts/screens";
import { ScreenBase } from "@frui.ts/screens";
import { groupBy } from "helpers/utils";
import type DataSyncService from "services/dataSyncService";
import type EnumsService from "services/enumsService";
import type UserContext from "services/userContext";
import SupplyPointListItem from "entities/supplyPointListItem";
import type HistoricalSupplyPointsService from "services/historicalSupplyPointsService";

export default class SupplyPointsViewModel extends ScreenBase {
  constructor(
    public localization: ILocalizationService,
    public router: Router,
    private userContext: UserContext,
    private dataService: DataSyncService,
    private enumsService: EnumsService,
    private historicalSupplyPointsService: HistoricalSupplyPointsService
  ) {
    super();
  }

  get supplyPointsByType() {
    const supplyPoints = this.dataService.supplyPoints;
    return groupBy(supplyPoints, x => this.enumsService.getCommodityType(x.commodityId)!);
  }

  @bound
  async updateHistoricalSupplyPoint(supplyPoint: SupplyPointListItem) {
    if (!this.userContext.activePartnerId) {
      return;
    }

    await this.historicalSupplyPointsService.updateHistoricalSupplyPoint(this.userContext.activePartnerId, supplyPoint);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`dashboard.${key}`);
  }
}
