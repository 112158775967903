import { observable } from "mobx";
import { Type, Transform } from "class-transformer";
import { Expose } from "class-transformer";
import Address from "./address";
import Contract from "./contract";
import Account from "./account";
import SupplyPointButtonSettings from "./supplyPointButtonSettings";
import { formatISO } from "date-fns";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class SupplyPoint {
  /**
   * @example
   * 200
   */
  id!: number;

  /**
   * @example
   * 123456789
   */
  @observable
  @Expose({ name: "external_id" })
  externalId!: string;

  @observable
  @Expose({ name: "account_id" })
  accountId!: number;

  /**
   * @example
   * Chata
   */
  @observable
  name!: string;

  /**
   * číslo odběrného místa
   */
  @observable
  @Expose({ name: "inst_id" })
  instId?: string;

  /**
   * @example
   * 859182400804904899
   */
  @observable
  code!: string;

  /**
   * Commodity id from enumeration
   */
  @observable
  @Expose({ name: "commodity_id" })
  commodityId!: number;

  @observable
  @Type(() => Address)
  address!: Address;

  /**
   * @example
   * Kolmá 1200/12a
   */
  @observable
  @Expose({ name: "address_text" })
  addressText!: string;

  /**
   * Enumeration::InstallationFloor
   */
  @observable
  @Expose({ name: "installation_floor_id" })
  installationFloorId?: number;

  @observable
  @Expose({ name: "flat_number" })
  flatNumber?: string;

  /**
   * @example
   * Září
   */
  @observable
  @Expose({ name: "reading_period" })
  readingPeriod?: string;

  /**
   * @example
   * 20.09.2020 - 31.10.2020
   */
  @observable
  @Expose({ name: "reading_interval" })
  readingInterval?: string;

  @observable
  @Expose({ name: "deposit_due_amount" })
  depositDueAmount!: number;

  @observable
  @Expose({ name: "deposit_due_count" })
  depositDueCount!: number;

  @observable
  @Expose({ name: "invoice_due_amount" })
  invoiceDueAmount!: number;

  @observable
  @Expose({ name: "invoice_due_count" })
  invoiceDueCount!: number;

  /**
   * Číslo měřáku
   */
  @observable
  @Expose({ name: "device_id" })
  deviceId?: string;

  /**
   * Tariff
   */
  @observable
  tariff?: string;

  /**
   * Jistič
   * @example
   * 1x25A
   */
  @observable
  @Expose({ name: "circuit_breaker" })
  circuitBreaker?: string;

  /**
   * Využití spotřebiče
   * @example
   * Kotel, Bojler
   */
  @observable
  appliances?: string;

  /**
   * Last meassured VT value
   */
  @observable
  @Expose({ name: "last_value" })
  lastValue?: number;

  /**
   * Last meassured VT value
   */
  @observable
  @Expose({ name: "last_value_nt" })
  lastValueNt?: number;

  /**
   * Usage of device
   */
  @observable
  usage?: string;

  /**
   * Distributor
   */
  @observable
  distributor?: string;

  @observable
  @Expose({ name: "dashboard_notice" })
  dashboardNotice?: string;

  @observable
  pinnable: boolean;

  @observable
  pinned: boolean;

  @observable
  state: string;

  @observable
  state_color: string;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "pinned_until" })
  pinnedUntil: Date;

  @observable
  @Type(() => Account)
  account!: Account;

  @observable
  @Type(() => Contract)
  contract!: Contract;

  @observable
  @Type(() => SupplyPointButtonSettings)
  settings: SupplyPointButtonSettings;

  static ValidationRules = {
    id: { required: true, number: true },
    externalId: { required: true },
    accountId: { required: true, number: true },
    name: { required: true },
    code: { required: true },
    commodityId: { required: true, number: true },
    address: { required: true },
    addressText: { required: true },
    installationFloorId: { number: true },
    depositDueAmount: { required: true, number: true },
    depositDueCount: { required: true, number: true },
    invoiceDueAmount: { required: true, number: true },
    invoiceDueCount: { required: true, number: true },
    lastValue: { number: true },
    lastValueNt: { number: true },
    account: { required: true },
    contract: { required: true },
  };
}
