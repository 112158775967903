import type { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { action, computed, observable, runInAction } from "mobx";
import type { interfaces } from "inversify";
import { createSupplyPointsWithIcons } from "helpers/supplyPointHelper";
import EnumsService from "services/enumsService";
import HistoricalSupplyPointsService from "services/historicalSupplyPointsService";
import type SupplyPointListItem from "entities/supplyPointListItem";

export default class ModalEditHistoricalSupplyPointsViewModel extends ScreenBase {
  static navigationName = "manage_historical";
  busyWatcher = new BusyWatcher();
  navigationName = ModalEditHistoricalSupplyPointsViewModel.navigationName;

  @observable.ref supplyPoints: SupplyPointListItem[] = [];

  constructor(
    public partnerId: number,
    public localization: ILocalizationService,
    private enumsService: EnumsService,
    private historicalSupplyPointsService: HistoricalSupplyPointsService
  ) {
    super();

    this.name = this.translate("manage_historical_supply_points");
  }

  @bound
  protected onInitialize() {
    runInAction(() => {
      this.supplyPoints = this.historicalSupplyPointsService.historicalSupplyPoints;
    });
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.customer_info.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  @bound @action toggleSupplyPoint(id: number) {
    const supplyPoint = this.supplyPoints.find(sp => sp.id === id);
    if (supplyPoint) {
      supplyPoint.pinned = !supplyPoint.pinned;
    }
  }

  @bound @action selectAll() {
    this.supplyPoints.forEach(sp => {
      sp.pinned = true;
    });
  }

  @bound @action deselectAll() {
    this.supplyPoints.forEach(sp => {
      sp.pinned = false;
    });
  }

  @computed
  get selectedSupplyPoints() {
    return this.supplyPoints.filter(sp => sp.pinned).map(sp => sp.id);
  }

  @computed
  get supplyPointsWithIcons() {
    const result = createSupplyPointsWithIcons(this.enumsService, this.supplyPoints);
    return result.map(supplyPoint => {
      let newTitle: string | undefined;
      if (supplyPoint.contractExternalId && supplyPoint.title) {
        newTitle = `${supplyPoint.contractExternalId} - ${supplyPoint.title}`;
      } else if (supplyPoint.contractExternalId) {
        newTitle = supplyPoint.contractExternalId;
      }

      return { ...supplyPoint, title: newTitle ?? supplyPoint.title };
    });
  }

  @action.bound
  @watchBusy
  async confirm() {
    await this.historicalSupplyPointsService.updateHistoricalSupplyPoints(this.partnerId, this.supplyPoints);
    await this.requestClose();
  }

  static Factory({ container }: interfaces.Context) {
    return (partnerId: number) => {
      return new ModalEditHistoricalSupplyPointsViewModel(
        partnerId,
        container.get("ILocalizationService"),
        container.get(EnumsService),
        container.get(HistoricalSupplyPointsService)
      );
    };
  }
}
