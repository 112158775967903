import { observable } from "mobx";
import { Expose, Type } from "class-transformer";
import SupplyPointListItemAccount from "./supplyPointListItemAccount";
import SupplyPointListItemContract from "./supplyPointListItemContract";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class SupplyPointSimple {
  id!: number;

  @observable
  name!: string;

  @observable
  code!: string;

  @observable
  @Expose({ name: "address_text" })
  addressText!: string;

  @observable
  @Expose({ name: "device_id" })
  deviceId?: string;

  @observable
  @Expose({ name: "commodity_id" })
  commodityId!: number;

  @observable
  @Expose({ name: "account_id" })
  accountId!: number;

  @observable
  @Type(() => SupplyPointListItemAccount)
  account: SupplyPointListItemAccount;

  @observable
  @Type(() => SupplyPointListItemContract)
  contract: SupplyPointListItemContract;

  static ValidationRules = {
    id: { required: true, number: true },
    name: { required: true },
    code: { required: true },
    addressText: { required: true },
    commodityId: { required: true, number: true },
    accountId: { required: true, number: true },
  };
}
