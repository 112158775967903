import React from "react";
import { Button } from "react-bootstrap";
import iconPin from "assets/pin.svg";
import iconPinned from "assets/pinned.svg";
import { rest } from "lodash";
import Tooltip from "@emanprague/basekit/dist/Tooltip";

interface PinButtonProps {
  className?: string;
  size?: number;
  pinned: boolean;
  onToggle: (pinned: boolean) => void;
  pinnedText: string;
  unpinnedText: string;
}

export const PinButton: React.FC<PinButtonProps> = ({ onToggle, pinned, className, pinnedText, unpinnedText, size = 32 }) => {
  const text = pinned ? pinnedText : unpinnedText;
  const alt = pinned ? "pinned" : "unpinned";
  return (
    <Button
      id="pin-toggle"
      style={{ padding: "4px" }}
      className={className}
      variant="secondary"
      onClick={() => onToggle(!pinned)}>
      <Tooltip id="pin-toggle-tooltip" tooltip={text} wrap {...rest} placement="top">
        <img width={size} height={size} src={pinned ? iconPinned : iconPin} alt={alt} />
      </Tooltip>
    </Button>
  );
};
