import RepositoryBase from "./repositoryBase";
import SupplyPointListItem from "entities/supplyPointListItem";

export default class HistoricalSupplyPointRepository extends RepositoryBase {
  getHistoricalSupplyPoints(partnerId: number) {
    return this.callApi(api => api.all(`partners/${partnerId}/historical_supply_points`).getEntities(SupplyPointListItem));
  }

  updateHistoricalSupplyPoints(partnerId: number, supplyPoints: SupplyPointListItem[], options = { reloadData: true }) {
    const data = supplyPoints.map(supply_point => ({
      id: supply_point.id,
      account_id: supply_point.account.externalId,
      contract_id: supply_point.contract.code,
      pinned: supply_point.pinned,
    }));

    return this.callApi(api =>
      api
        .all(`partners/${partnerId}/historical_supply_points`)
        .putEntity({ supply_points: data, reload_data: options.reloadData })
    );
  }
}
