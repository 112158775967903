import type UserContext from "./userContext";
import { observable, runInAction } from "mobx";
import type SupplyPointListItem from "entities/supplyPointListItem";
import type HistoricalSupplyPointRepository from "repositories/historicalSupplyPointsRepository";
import type { INotificationService, ILocalizationService } from "@emanprague/shared-services";
import { SeverityLevel } from "@emanprague/shared-services";

export default class HistoricalSupplyPointsService {
  constructor(
    private userContext: UserContext,
    private notificationService: INotificationService,
    private repository: HistoricalSupplyPointRepository,
    private localization: ILocalizationService
  ) {}

  @observable.ref historicalSupplyPoints: SupplyPointListItem[] = [];

  async loadHistoricalSupplyPoints() {
    const partnerId = this.userContext.activePartnerId;
    if (!partnerId) {
      return [];
    }

    const response = await this.repository.getHistoricalSupplyPoints(partnerId);
    if (response.success) {
      runInAction(() => {
        this.historicalSupplyPoints = response.payload;
      });
    } else {
      runInAction(() => {
        this.historicalSupplyPoints = [];
      });
    }
  }

  // Updates the historical supply points for the current partner, does not reload data as it is not needed.
  // Reload of SupplyPoints will be triggered from the BE via websocket.
  async updateHistoricalSupplyPoints(partnerId: number, data: SupplyPointListItem[], options = { reloadData: true }) {
    const result = await this.repository.updateHistoricalSupplyPoints(partnerId, data, options);
    if (result.success) {
      runInAction(() => {
        this.notificationService.addNotification(this.translateGeneral("setting_saved"), SeverityLevel.success);
      });
    }
  }

  translateGeneral(code: string) {
    return this.localization.translateGeneral(`general.${code}`);
  }

  // Convenience method to update a single supply point, will reload historical supply points from BE
  async updateHistoricalSupplyPoint(partnerId: number, supplyPointItem: SupplyPointListItem) {
    const result = await this.repository.updateHistoricalSupplyPoints(partnerId, [supplyPointItem], { reloadData: false });
    await this.loadHistoricalSupplyPoints();
    return result;
  }
}
