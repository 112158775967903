// WARNING: This file has been generated. Do not edit it manually, your changes might get lost.
import type { Container, interfaces } from "inversify";
import repositoriesAccountsRepository from "./repositories/accountsRepository";
import repositoriesAdminRepository from "./repositories/adminRepository";
import repositoriesAdvancesRepository from "./repositories/advancesRepository";
import repositoriesAttachmentsRepository from "./repositories/attachmentsRepository";
import repositoriesContractRepository from "./repositories/contractRepository";
import repositoriesEnumsRepository from "./repositories/enumsRepository";
import repositoriesGeneralSettingsRepository from "./repositories/generalSettingsRepository";
import repositoriesHistoricalSupplyPointsRepository from "./repositories/historicalSupplyPointsRepository";
import repositoriesInvoicesRepository from "./repositories/invoicesRepository";
import repositoriesLinksRepository from "./repositories/linksRepository";
import repositoriesLoginRepository from "./repositories/loginRepository";
import repositoriesMessagesRepository from "./repositories/messagesRepository";
import repositoriesNotificationsRepository from "./repositories/notificationsRepository";
import repositoriesPartnersRepository from "./repositories/partnersRepository";
import repositoriesPaymentsRepository from "./repositories/paymentsRepository";
import repositoriesPortalSettingsRepository from "./repositories/portalSettingsRepository";
import repositoriesProductCardsRepository from "./repositories/productCardsRepository";
import repositoriesReadingsRepository from "./repositories/readingsRepository";
import repositoriesRequestsRepository from "./repositories/requestsRepository";
import repositoriesRuianRepository from "./repositories/ruianRepository";
import repositoriesSupplyPointsRepository from "./repositories/supplyPointsRepository";
import servicesAppendixesService from "./services/appendixesService";
import servicesBannersService from "./services/bannersService";
import servicesConfirmationService from "./services/confirmationService";
import servicesDataSyncService from "./services/dataSyncService";
import servicesDownloadingService from "./services/downloadingService";
import servicesEnumsService from "./services/enumsService";
import { EventsService as servicesEventsEventsService } from "./services/events";
import servicesGaService from "./services/gaService";
import servicesHistoricalSupplyPointsService from "./services/historicalSupplyPointsService";
import servicesInitializationService from "./services/initializationService";
import servicesLocaleProvider from "./services/localeProvider";
import servicesMessagesService from "./services/messagesService";
import servicesNotificationService from "./services/notificationService";
import servicesPortalSettingsService from "./services/portalSettingsService";
import servicesProductCardsService from "./services/productCardsService";
import servicesRequestsService from "./services/requestsService";
import servicesSecurityService from "./services/securityService";
import servicesServerCommunicationProvider from "./services/serverCommunicationProvider";
import servicesValidationPasswordValidationService from "./services/validation/passwordValidationService";
import servicesValidationValidationService from "./services/validation/validationService";
import viewModelsAddressChangeViewModel from "./viewModels/addressChangeViewModel";
import viewModelsAdminAdminCreateViewModel from "./viewModels/admin/adminCreateViewModel";
import viewModelsAdminAdminsPageViewModel from "./viewModels/admin/adminsPageViewModel";
import viewModelsAdminAdminUpdateViewModel from "./viewModels/admin/adminUpdateViewModel";
import viewModelsAdminAuditLogsPageViewModel from "./viewModels/admin/auditLogsPageViewModel";
import viewModelsAdminBannerDetailViewModel from "./viewModels/admin/bannerDetailViewModel";
import viewModelsAdminBannerPreviewViewModel from "./viewModels/admin/bannerPreviewViewModel";
import viewModelsAdminBannersPageViewModel from "./viewModels/admin/bannersPageViewModel";
import viewModelsAdminGeneralSettingsPageViewModel from "./viewModels/admin/generalSettingsPageViewModel";
import viewModelsAdminLinksDetailViewModel from "./viewModels/admin/linksDetailViewModel";
import viewModelsAdminLinksSettingsPageViewModel from "./viewModels/admin/linksSettingsPageViewModel";
import viewModelsAdminMessageDetailViewModel from "./viewModels/admin/messageDetailViewModel";
import viewModelsAdminMessagePreviewViewModel from "./viewModels/admin/messagePreviewViewModel";
import viewModelsAdminMessagesPageViewModel from "./viewModels/admin/messagesPageViewModel";
import viewModelsAdminNoticeSettingsPageViewModel from "./viewModels/admin/noticeSettingsPageViewModel";
import viewModelsAdminProductCardsProductCardDetailViewModel from "./viewModels/admin/productCards/productCardDetailViewModel";
import viewModelsAdminProductCardsProductCardPreviewViewModel from "./viewModels/admin/productCards/productCardPreviewViewModel";
import viewModelsAdminProductCardsProductCardsPageViewModel from "./viewModels/admin/productCards/productCardsPageViewModel";
import viewModelsAdminScreenSharePageViewModel from "./viewModels/admin/screenSharePageViewModel";
import viewModelsAdminRootViewModel from "./viewModels/adminRootViewModel";
import viewModelsAnonymousRootViewModel from "./viewModels/anonymousRootViewModel";
import viewModelsConfirmDialogViewModel from "./viewModels/confirmDialogViewModel";
import viewModelsDashboardAttachmentsAttachmentCreateViewModel from "./viewModels/dashboard/attachments/attachmentCreateViewModel";
import viewModelsDashboardAttachmentsAttachmentsViewModel from "./viewModels/dashboard/attachments/attachmentsViewModel";
import viewModelsDashboardAttachmentsAttachmentUpdateViewModel from "./viewModels/dashboard/attachments/attachmentUpdateViewModel";
import viewModelsDashboardCustomerAccountViewModel from "./viewModels/dashboard/customerAccountViewModel";
import viewModelsDashboardDashboardViewModel from "./viewModels/dashboard/dashboardViewModel";
import viewModelsDashboardOverviewViewModel from "./viewModels/dashboard/overviewViewModel";
import viewModelsDashboardPartnerConfirmViewModel from "./viewModels/dashboard/partnerConfirmViewModel";
import viewModelsDashboardPartnerConnectViewModel from "./viewModels/dashboard/partnerConnectViewModel";
import viewModelsDashboardPartnerRemoveViewModel from "./viewModels/dashboard/partnerRemoveViewModel";
import viewModelsDashboardWidgetsSupplyPointsViewModel from "./viewModels/dashboard/widgets/supplyPointsViewModel";
import viewModelsFinanceAdvanceChangeViewModel from "./viewModels/finance/advanceChangeViewModel";
import viewModelsFinanceAdvanceDetailViewModel from "./viewModels/finance/advanceDetailViewModel";
import viewModelsFinanceAdvancesPageViewModel from "./viewModels/finance/advancesPageViewModel";
import viewModelsFinanceInvoiceComplaintViewModel from "./viewModels/finance/invoiceComplaintViewModel";
import viewModelsFinanceInvoiceDetailViewModel from "./viewModels/finance/invoiceDetailViewModel";
import viewModelsFinanceInvoicesPageViewModel from "./viewModels/finance/invoicesPageViewModel";
import viewModelsFinanceOnlinePaymentViewModel from "./viewModels/finance/onlinePaymentViewModel";
import viewModelsFinanceOverviewViewModel from "./viewModels/finance/overviewViewModel";
import viewModelsFinancePaymentsPageViewModel from "./viewModels/finance/paymentsPageViewModel";
import viewModelsMessagesOverviewViewModel from "./viewModels/messages/overviewViewModel";
import viewModelsProductsAppendixesPageViewModel from "./viewModels/products/appendixesPageViewModel";
import viewModelsProductsAxaPageViewModel from "./viewModels/products/axaPageViewModel";
import viewModelsProductsCngPageViewModel from "./viewModels/products/cngPageViewModel";
import viewModelsProductsCustomerCardPageViewModel from "./viewModels/products/customerCardPageViewModel";
import viewModelsProductsMyProductsDetailPagesAdvancesDetailPageViewModel from "./viewModels/products/myProducts/detailPages/advancesDetailPageViewModel";
import viewModelsProductsMyProductsDetailPagesContractContractDetailPageViewModel from "./viewModels/products/myProducts/detailPages/contract/contractDetailPageViewModel";
import viewModelsProductsMyProductsDetailPagesContractModalDocumentsViewModel from "./viewModels/products/myProducts/detailPages/contract/modalDocumentsViewModel";
import viewModelsProductsMyProductsDetailPagesCustomerInfoCustomerInfoDetailPageViewModel from "./viewModels/products/myProducts/detailPages/customerInfo/customerInfoDetailPageViewModel";
import viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditContactInfoViewModel from "./viewModels/products/myProducts/detailPages/customerInfo/modalEditContactInfoViewModel";
import viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditCustomerInfoViewModel from "./viewModels/products/myProducts/detailPages/customerInfo/modalEditCustomerInfoViewModel";
import viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditHistoricalSupplyPoints from "./viewModels/products/myProducts/detailPages/customerInfo/modalEditHistoricalSupplyPoints";
import viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditMailingAddressViewModel from "./viewModels/products/myProducts/detailPages/customerInfo/modalEditMailingAddressViewModel";
import viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditMarketingAgreementViewModel from "./viewModels/products/myProducts/detailPages/customerInfo/modalEditMarketingAgreementViewModel";
import viewModelsProductsMyProductsDetailPagesHistoryBulkSelfReadingViewModel from "./viewModels/products/myProducts/detailPages/history/bulkSelfReadingViewModel";
import viewModelsProductsMyProductsDetailPagesHistoryChartViewModel from "./viewModels/products/myProducts/detailPages/history/chartViewModel";
import viewModelsProductsMyProductsDetailPagesHistoryHistoryDetailPageViewModel from "./viewModels/products/myProducts/detailPages/history/historyDetailPageViewModel";
import viewModelsProductsMyProductsDetailPagesHistoryModalSelfReadingViewModel from "./viewModels/products/myProducts/detailPages/history/modalSelfReadingViewModel";
import viewModelsProductsMyProductsDetailPagesHistorySingleSelfReadingViewModel from "./viewModels/products/myProducts/detailPages/history/singleSelfReadingViewModel";
import viewModelsProductsMyProductsDetailPagesHistoryTableViewModel from "./viewModels/products/myProducts/detailPages/history/tableViewModel";
import viewModelsProductsMyProductsDetailPagesInvoicesDetailPageViewModel from "./viewModels/products/myProducts/detailPages/invoicesDetailPageViewModel";
import viewModelsProductsMyProductsDetailPagesOptionsDepositModalAdvancePaymentMethodViewModel from "./viewModels/products/myProducts/detailPages/optionsDeposit/modalAdvancePaymentMethodViewModel";
import viewModelsProductsMyProductsDetailPagesOptionsDepositModalInvoiceMailingMethodViewModel from "./viewModels/products/myProducts/detailPages/optionsDeposit/modalInvoiceMailingMethodViewModel";
import viewModelsProductsMyProductsDetailPagesOptionsDepositModalInvoicePaymentMethodViewModel from "./viewModels/products/myProducts/detailPages/optionsDeposit/modalInvoicePaymentMethodViewModel";
import viewModelsProductsMyProductsDetailPagesOptionsDepositOptionsDepositDetailPageViewModel from "./viewModels/products/myProducts/detailPages/optionsDeposit/optionsDepositDetailPageViewModel";
import viewModelsProductsMyProductsDetailPagesPaymentsDetailPageViewModel from "./viewModels/products/myProducts/detailPages/paymentsDetailPageViewModel";
import viewModelsProductsMyProductsDetailPagesSupplyPointCustomerTransferViewModel from "./viewModels/products/myProducts/detailPages/supplyPoint/customerTransferViewModel";
import viewModelsProductsMyProductsDetailPagesSupplyPointModalEditSupplyPointViewModel from "./viewModels/products/myProducts/detailPages/supplyPoint/modalEditSupplyPointViewModel";
import viewModelsProductsMyProductsDetailPagesSupplyPointSupplyPointDetailPageViewModel from "./viewModels/products/myProducts/detailPages/supplyPoint/supplyPointDetailPageViewModel";
import viewModelsProductsMyProductsProductDetailViewModel from "./viewModels/products/myProducts/productDetailViewModel";
import viewModelsProductsOtherProductsPageViewModel from "./viewModels/products/otherProductsPageViewModel";
import viewModelsProductsOverviewViewModel from "./viewModels/products/overviewViewModel";
import viewModelsProfileCompetitionsViewModel from "./viewModels/profile/competitionsViewModel";
import viewModelsProfileConfirmRegistrationViewModel from "./viewModels/profile/confirmRegistrationViewModel";
import viewModelsProfileForgottenPasswordViewModel from "./viewModels/profile/forgottenPasswordViewModel";
import viewModelsProfileLoginViewModel from "./viewModels/profile/loginViewModel";
import viewModelsProfileRefreshCredentialsViewModel from "./viewModels/profile/refreshCredentialsViewModel";
import viewModelsProfileRegistrationViewModel from "./viewModels/profile/registrationViewModel";
import viewModelsProfileResetPasswordViewModel from "./viewModels/profile/resetPasswordViewModel";
import viewModelsProfileStatusViewModel from "./viewModels/profile/statusViewModel";
import viewModelsProfileUpdateEmailViewModel from "./viewModels/profile/updateEmailViewModel";
import viewModelsProfileUpdatePasswordViewModel from "./viewModels/profile/updatePasswordViewModel";
import viewModelsRequestsModalCreateRequestViewModel from "./viewModels/requests/modalCreateRequestViewModel";
import viewModelsRequestsOverviewViewModel from "./viewModels/requests/overviewViewModel";
import viewModelsRootViewModel from "./viewModels/rootViewModel";
import viewModelsSupplyPointsOverviewViewModel from "./viewModels/supplyPoints/overviewViewModel";
import viewModelsToastViewModel from "./viewModels/toastViewModel";

export default function registerServices(container: Container) {
  container.bind<repositoriesAccountsRepository>(repositoriesAccountsRepository).toSelf();
  container.bind<repositoriesAdminRepository>(repositoriesAdminRepository).toSelf();
  container.bind<repositoriesAdvancesRepository>(repositoriesAdvancesRepository).toSelf();
  container.bind<repositoriesAttachmentsRepository>(repositoriesAttachmentsRepository).toSelf();
  container.bind<repositoriesContractRepository>(repositoriesContractRepository).toSelf();
  container.bind<repositoriesEnumsRepository>(repositoriesEnumsRepository).toSelf();
  container.bind<repositoriesGeneralSettingsRepository>(repositoriesGeneralSettingsRepository).toSelf();
  container.bind<repositoriesHistoricalSupplyPointsRepository>(repositoriesHistoricalSupplyPointsRepository).toSelf();
  container.bind<repositoriesInvoicesRepository>(repositoriesInvoicesRepository).toSelf();
  container.bind<repositoriesLinksRepository>(repositoriesLinksRepository).toSelf();
  container.bind<repositoriesLoginRepository>(repositoriesLoginRepository).toSelf();
  container.bind<repositoriesMessagesRepository>(repositoriesMessagesRepository).toSelf();
  container.bind<repositoriesNotificationsRepository>(repositoriesNotificationsRepository).toSelf();
  container.bind<repositoriesPartnersRepository>(repositoriesPartnersRepository).toSelf();
  container.bind<repositoriesPaymentsRepository>(repositoriesPaymentsRepository).toSelf();
  container.bind<repositoriesPortalSettingsRepository>(repositoriesPortalSettingsRepository).toSelf();
  container.bind<repositoriesProductCardsRepository>(repositoriesProductCardsRepository).toSelf();
  container.bind<repositoriesReadingsRepository>(repositoriesReadingsRepository).toSelf();
  container.bind<repositoriesRequestsRepository>(repositoriesRequestsRepository).toSelf();
  container.bind<repositoriesRuianRepository>(repositoriesRuianRepository).toSelf();
  container.bind<repositoriesSupplyPointsRepository>(repositoriesSupplyPointsRepository).toSelf();
  container.bind<servicesAppendixesService>(servicesAppendixesService).toSelf().inSingletonScope();
  container.bind<servicesBannersService>(servicesBannersService).toSelf().inSingletonScope();
  container.bind<servicesConfirmationService>(servicesConfirmationService).toSelf().inSingletonScope();
  container.bind<servicesDataSyncService>(servicesDataSyncService).toSelf().inSingletonScope();
  container.bind<servicesDownloadingService>(servicesDownloadingService).toSelf().inSingletonScope();
  container.bind<servicesEnumsService>(servicesEnumsService).toSelf().inSingletonScope();
  container.bind<servicesEventsEventsService>("IEventBus").to(servicesEventsEventsService).inSingletonScope();
  container.bind<servicesGaService>(servicesGaService).toSelf().inSingletonScope();
  container.bind<servicesHistoricalSupplyPointsService>(servicesHistoricalSupplyPointsService).toSelf().inSingletonScope();
  container.bind<servicesInitializationService>("IInitializationService").to(servicesInitializationService).inSingletonScope();
  container.bind<servicesLocaleProvider>(servicesLocaleProvider).toSelf();
  container.bind<servicesMessagesService>(servicesMessagesService).toSelf().inSingletonScope();
  container.bind<servicesNotificationService>("INotificationService").to(servicesNotificationService).inSingletonScope();
  container.bind<servicesPortalSettingsService>(servicesPortalSettingsService).toSelf().inSingletonScope();
  container.bind<servicesProductCardsService>(servicesProductCardsService).toSelf().inSingletonScope();
  container.bind<servicesRequestsService>(servicesRequestsService).toSelf().inSingletonScope();
  container.bind<servicesSecurityService>(servicesSecurityService).toSelf().inSingletonScope();
  container.bind<servicesServerCommunicationProvider>(servicesServerCommunicationProvider).toSelf();
  container
    .bind<interfaces.Factory<viewModelsAddressChangeViewModel>>(viewModelsAddressChangeViewModel.Factory)
    .toFactory(viewModelsAddressChangeViewModel.Factory);
  container.bind<viewModelsAdminRootViewModel>(viewModelsAdminRootViewModel).toSelf();
  container.bind<viewModelsAnonymousRootViewModel>(viewModelsAnonymousRootViewModel).toSelf();
  container.bind<viewModelsConfirmDialogViewModel>(viewModelsConfirmDialogViewModel).toSelf();
  container.bind<viewModelsRootViewModel>(viewModelsRootViewModel).toSelf();
  container.bind<viewModelsToastViewModel>(viewModelsToastViewModel).toSelf();
  container
    .bind<servicesValidationPasswordValidationService>(servicesValidationPasswordValidationService)
    .toSelf()
    .inSingletonScope();
  container.bind<servicesValidationValidationService>(servicesValidationValidationService).toSelf().inSingletonScope();
  container
    .bind<interfaces.Factory<viewModelsAdminAdminCreateViewModel>>(viewModelsAdminAdminCreateViewModel.Factory)
    .toFactory(viewModelsAdminAdminCreateViewModel.Factory);
  container.bind<viewModelsAdminAdminsPageViewModel>("IAdminModule").to(viewModelsAdminAdminsPageViewModel);
  container
    .bind<interfaces.Factory<viewModelsAdminAdminUpdateViewModel>>(viewModelsAdminAdminUpdateViewModel.Factory)
    .toFactory(viewModelsAdminAdminUpdateViewModel.Factory);
  container.bind<viewModelsAdminAuditLogsPageViewModel>("IAdminModule").to(viewModelsAdminAuditLogsPageViewModel);
  container
    .bind<interfaces.Factory<viewModelsAdminBannerDetailViewModel>>(viewModelsAdminBannerDetailViewModel.Factory)
    .toFactory(viewModelsAdminBannerDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsAdminBannerPreviewViewModel>>(viewModelsAdminBannerPreviewViewModel.Factory)
    .toFactory(viewModelsAdminBannerPreviewViewModel.Factory);
  container.bind<viewModelsAdminBannersPageViewModel>("IAdminModule").to(viewModelsAdminBannersPageViewModel);
  container.bind<viewModelsAdminGeneralSettingsPageViewModel>("IAdminModule").to(viewModelsAdminGeneralSettingsPageViewModel);
  container
    .bind<interfaces.Factory<viewModelsAdminLinksDetailViewModel>>(viewModelsAdminLinksDetailViewModel.Factory)
    .toFactory(viewModelsAdminLinksDetailViewModel.Factory);
  container.bind<viewModelsAdminLinksSettingsPageViewModel>("IAdminModule").to(viewModelsAdminLinksSettingsPageViewModel);
  container
    .bind<interfaces.Factory<viewModelsAdminMessageDetailViewModel>>(viewModelsAdminMessageDetailViewModel.Factory)
    .toFactory(viewModelsAdminMessageDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsAdminMessagePreviewViewModel>>(viewModelsAdminMessagePreviewViewModel.Factory)
    .toFactory(viewModelsAdminMessagePreviewViewModel.Factory);
  container.bind<viewModelsAdminMessagesPageViewModel>("IAdminModule").to(viewModelsAdminMessagesPageViewModel);
  container.bind<viewModelsAdminNoticeSettingsPageViewModel>("IAdminModule").to(viewModelsAdminNoticeSettingsPageViewModel);
  container.bind<viewModelsAdminScreenSharePageViewModel>("IAdminModule").to(viewModelsAdminScreenSharePageViewModel);
  container.bind<viewModelsDashboardCustomerAccountViewModel>(viewModelsDashboardCustomerAccountViewModel).toSelf();
  container.bind<viewModelsDashboardDashboardViewModel>(viewModelsDashboardDashboardViewModel).toSelf();
  container.bind<viewModelsDashboardOverviewViewModel>("IModule").to(viewModelsDashboardOverviewViewModel);
  container.bind<viewModelsDashboardPartnerConfirmViewModel>(viewModelsDashboardPartnerConfirmViewModel).toSelf();
  container.bind("Factory<PartnerConfirmViewModel>").toAutoFactory(viewModelsDashboardPartnerConfirmViewModel);
  container.bind<viewModelsDashboardPartnerConnectViewModel>(viewModelsDashboardPartnerConnectViewModel).toSelf();
  container.bind<viewModelsDashboardPartnerRemoveViewModel>(viewModelsDashboardPartnerRemoveViewModel).toSelf();
  container.bind("Factory<PartnerRemoveViewModel>").toAutoFactory(viewModelsDashboardPartnerRemoveViewModel);
  container
    .bind<interfaces.Factory<viewModelsFinanceAdvanceChangeViewModel>>(viewModelsFinanceAdvanceChangeViewModel.Factory)
    .toFactory(viewModelsFinanceAdvanceChangeViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsFinanceAdvanceDetailViewModel>>(viewModelsFinanceAdvanceDetailViewModel.Factory)
    .toFactory(viewModelsFinanceAdvanceDetailViewModel.Factory);
  container.bind<viewModelsFinanceAdvancesPageViewModel>("IFinancePage").to(viewModelsFinanceAdvancesPageViewModel);
  container
    .bind<interfaces.Factory<viewModelsFinanceInvoiceComplaintViewModel>>(viewModelsFinanceInvoiceComplaintViewModel.Factory)
    .toFactory(viewModelsFinanceInvoiceComplaintViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsFinanceInvoiceDetailViewModel>>(viewModelsFinanceInvoiceDetailViewModel.Factory)
    .toFactory(viewModelsFinanceInvoiceDetailViewModel.Factory);
  container.bind<viewModelsFinanceInvoicesPageViewModel>("IFinancePage").to(viewModelsFinanceInvoicesPageViewModel);
  container
    .bind<interfaces.Factory<viewModelsFinanceOnlinePaymentViewModel>>(viewModelsFinanceOnlinePaymentViewModel.Factory)
    .toFactory(viewModelsFinanceOnlinePaymentViewModel.Factory);
  container.bind<viewModelsFinanceOverviewViewModel>("IModule").to(viewModelsFinanceOverviewViewModel);
  container.bind<viewModelsFinancePaymentsPageViewModel>("IFinancePage").to(viewModelsFinancePaymentsPageViewModel);
  container.bind<viewModelsMessagesOverviewViewModel>("IModule").to(viewModelsMessagesOverviewViewModel);
  container.bind<viewModelsProductsAppendixesPageViewModel>("IProductPage").to(viewModelsProductsAppendixesPageViewModel);
  container.bind<viewModelsProductsAxaPageViewModel>("IProductPage").to(viewModelsProductsAxaPageViewModel);
  container.bind<viewModelsProductsCngPageViewModel>("IProductPage").to(viewModelsProductsCngPageViewModel);
  container.bind<viewModelsProductsCustomerCardPageViewModel>("IProductPage").to(viewModelsProductsCustomerCardPageViewModel);
  container.bind<viewModelsProductsOtherProductsPageViewModel>("IProductPage").to(viewModelsProductsOtherProductsPageViewModel);
  container.bind<viewModelsProductsOverviewViewModel>("IModule").to(viewModelsProductsOverviewViewModel);
  container.bind<viewModelsProfileCompetitionsViewModel>(viewModelsProfileCompetitionsViewModel).toSelf();
  container.bind<viewModelsProfileConfirmRegistrationViewModel>(viewModelsProfileConfirmRegistrationViewModel).toSelf();
  container.bind("Factory<ConfirmRegistrationViewModel>").toAutoFactory(viewModelsProfileConfirmRegistrationViewModel);
  container.bind<viewModelsProfileForgottenPasswordViewModel>(viewModelsProfileForgottenPasswordViewModel).toSelf();
  container.bind("Factory<ForgottenPasswordViewModel>").toAutoFactory(viewModelsProfileForgottenPasswordViewModel);
  container.bind<viewModelsProfileLoginViewModel>(viewModelsProfileLoginViewModel).toSelf();
  container.bind<viewModelsProfileRefreshCredentialsViewModel>(viewModelsProfileRefreshCredentialsViewModel).toSelf();
  container.bind("Factory<RefreshCredentialsViewModel>").toAutoFactory(viewModelsProfileRefreshCredentialsViewModel);
  container.bind<viewModelsProfileRegistrationViewModel>(viewModelsProfileRegistrationViewModel).toSelf();
  container.bind("Factory<RegistrationViewModel>").toAutoFactory(viewModelsProfileRegistrationViewModel);
  container.bind<viewModelsProfileResetPasswordViewModel>(viewModelsProfileResetPasswordViewModel).toSelf();
  container.bind("Factory<ResetPasswordViewModel>").toAutoFactory(viewModelsProfileResetPasswordViewModel);
  container.bind<viewModelsProfileStatusViewModel>(viewModelsProfileStatusViewModel).toSelf();
  container.bind<viewModelsProfileUpdateEmailViewModel>(viewModelsProfileUpdateEmailViewModel).toSelf();
  container.bind("Factory<UpdateEmailViewModel>").toAutoFactory(viewModelsProfileUpdateEmailViewModel);
  container.bind<viewModelsProfileUpdatePasswordViewModel>(viewModelsProfileUpdatePasswordViewModel).toSelf();
  container.bind("Factory<UpdatePasswordViewModel>").toAutoFactory(viewModelsProfileUpdatePasswordViewModel);
  container
    .bind<interfaces.Factory<viewModelsRequestsModalCreateRequestViewModel>>(
      viewModelsRequestsModalCreateRequestViewModel.Factory
    )
    .toFactory(viewModelsRequestsModalCreateRequestViewModel.Factory);
  container.bind<viewModelsRequestsOverviewViewModel>("IModule").to(viewModelsRequestsOverviewViewModel);
  container.bind<viewModelsSupplyPointsOverviewViewModel>("IModule").to(viewModelsSupplyPointsOverviewViewModel);
  container
    .bind<interfaces.Factory<viewModelsAdminProductCardsProductCardDetailViewModel>>(
      viewModelsAdminProductCardsProductCardDetailViewModel.Factory
    )
    .toFactory(viewModelsAdminProductCardsProductCardDetailViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsAdminProductCardsProductCardPreviewViewModel>>(
      viewModelsAdminProductCardsProductCardPreviewViewModel.Factory
    )
    .toFactory(viewModelsAdminProductCardsProductCardPreviewViewModel.Factory);
  container
    .bind<viewModelsAdminProductCardsProductCardsPageViewModel>("IAdminModule")
    .to(viewModelsAdminProductCardsProductCardsPageViewModel);
  container
    .bind<interfaces.Factory<viewModelsDashboardAttachmentsAttachmentCreateViewModel>>(
      viewModelsDashboardAttachmentsAttachmentCreateViewModel.Factory
    )
    .toFactory(viewModelsDashboardAttachmentsAttachmentCreateViewModel.Factory);
  container.bind<viewModelsDashboardAttachmentsAttachmentsViewModel>(viewModelsDashboardAttachmentsAttachmentsViewModel).toSelf();
  container
    .bind<interfaces.Factory<viewModelsDashboardAttachmentsAttachmentUpdateViewModel>>(
      viewModelsDashboardAttachmentsAttachmentUpdateViewModel.Factory
    )
    .toFactory(viewModelsDashboardAttachmentsAttachmentUpdateViewModel.Factory);
  container.bind<viewModelsDashboardWidgetsSupplyPointsViewModel>(viewModelsDashboardWidgetsSupplyPointsViewModel).toSelf();
  container
    .bind<interfaces.Factory<viewModelsProductsMyProductsProductDetailViewModel>>(
      viewModelsProductsMyProductsProductDetailViewModel.Factory
    )
    .toFactory(viewModelsProductsMyProductsProductDetailViewModel.Factory);
  container
    .bind<viewModelsProductsMyProductsDetailPagesAdvancesDetailPageViewModel>("IDetailPage")
    .to(viewModelsProductsMyProductsDetailPagesAdvancesDetailPageViewModel);
  container
    .bind<viewModelsProductsMyProductsDetailPagesInvoicesDetailPageViewModel>("IDetailPage")
    .to(viewModelsProductsMyProductsDetailPagesInvoicesDetailPageViewModel);
  container
    .bind<viewModelsProductsMyProductsDetailPagesPaymentsDetailPageViewModel>("IDetailPage")
    .to(viewModelsProductsMyProductsDetailPagesPaymentsDetailPageViewModel);
  container
    .bind<viewModelsProductsMyProductsDetailPagesContractContractDetailPageViewModel>("IDetailPage")
    .to(viewModelsProductsMyProductsDetailPagesContractContractDetailPageViewModel);
  container
    .bind<interfaces.Factory<viewModelsProductsMyProductsDetailPagesContractModalDocumentsViewModel>>(
      viewModelsProductsMyProductsDetailPagesContractModalDocumentsViewModel.Factory
    )
    .toFactory(viewModelsProductsMyProductsDetailPagesContractModalDocumentsViewModel.Factory);
  container
    .bind<viewModelsProductsMyProductsDetailPagesCustomerInfoCustomerInfoDetailPageViewModel>("IDetailPage")
    .to(viewModelsProductsMyProductsDetailPagesCustomerInfoCustomerInfoDetailPageViewModel);
  container
    .bind<interfaces.Factory<viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditContactInfoViewModel>>(
      viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditContactInfoViewModel.Factory
    )
    .toFactory(viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditContactInfoViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditCustomerInfoViewModel>>(
      viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditCustomerInfoViewModel.Factory
    )
    .toFactory(viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditCustomerInfoViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditHistoricalSupplyPoints>>(
      viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditHistoricalSupplyPoints.Factory
    )
    .toFactory(viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditHistoricalSupplyPoints.Factory);
  container
    .bind<interfaces.Factory<viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditMailingAddressViewModel>>(
      viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditMailingAddressViewModel.Factory
    )
    .toFactory(viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditMailingAddressViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditMarketingAgreementViewModel>>(
      viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditMarketingAgreementViewModel.Factory
    )
    .toFactory(viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditMarketingAgreementViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsProductsMyProductsDetailPagesHistoryBulkSelfReadingViewModel>>(
      viewModelsProductsMyProductsDetailPagesHistoryBulkSelfReadingViewModel.Factory
    )
    .toFactory(viewModelsProductsMyProductsDetailPagesHistoryBulkSelfReadingViewModel.Factory);
  container
    .bind<viewModelsProductsMyProductsDetailPagesHistoryChartViewModel>(
      viewModelsProductsMyProductsDetailPagesHistoryChartViewModel
    )
    .toSelf();
  container
    .bind<viewModelsProductsMyProductsDetailPagesHistoryHistoryDetailPageViewModel>("IDetailPage")
    .to(viewModelsProductsMyProductsDetailPagesHistoryHistoryDetailPageViewModel);
  container
    .bind<interfaces.Factory<viewModelsProductsMyProductsDetailPagesHistoryModalSelfReadingViewModel>>(
      viewModelsProductsMyProductsDetailPagesHistoryModalSelfReadingViewModel.Factory
    )
    .toFactory(viewModelsProductsMyProductsDetailPagesHistoryModalSelfReadingViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsProductsMyProductsDetailPagesHistorySingleSelfReadingViewModel>>(
      viewModelsProductsMyProductsDetailPagesHistorySingleSelfReadingViewModel.Factory
    )
    .toFactory(viewModelsProductsMyProductsDetailPagesHistorySingleSelfReadingViewModel.Factory);
  container
    .bind<viewModelsProductsMyProductsDetailPagesHistoryTableViewModel>(
      viewModelsProductsMyProductsDetailPagesHistoryTableViewModel
    )
    .toSelf();
  container
    .bind<interfaces.Factory<viewModelsProductsMyProductsDetailPagesOptionsDepositModalAdvancePaymentMethodViewModel>>(
      viewModelsProductsMyProductsDetailPagesOptionsDepositModalAdvancePaymentMethodViewModel.Factory
    )
    .toFactory(viewModelsProductsMyProductsDetailPagesOptionsDepositModalAdvancePaymentMethodViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsProductsMyProductsDetailPagesOptionsDepositModalInvoiceMailingMethodViewModel>>(
      viewModelsProductsMyProductsDetailPagesOptionsDepositModalInvoiceMailingMethodViewModel.Factory
    )
    .toFactory(viewModelsProductsMyProductsDetailPagesOptionsDepositModalInvoiceMailingMethodViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsProductsMyProductsDetailPagesOptionsDepositModalInvoicePaymentMethodViewModel>>(
      viewModelsProductsMyProductsDetailPagesOptionsDepositModalInvoicePaymentMethodViewModel.Factory
    )
    .toFactory(viewModelsProductsMyProductsDetailPagesOptionsDepositModalInvoicePaymentMethodViewModel.Factory);
  container
    .bind<viewModelsProductsMyProductsDetailPagesOptionsDepositOptionsDepositDetailPageViewModel>("IDetailPage")
    .to(viewModelsProductsMyProductsDetailPagesOptionsDepositOptionsDepositDetailPageViewModel);
  container
    .bind<interfaces.Factory<viewModelsProductsMyProductsDetailPagesSupplyPointCustomerTransferViewModel>>(
      viewModelsProductsMyProductsDetailPagesSupplyPointCustomerTransferViewModel.Factory
    )
    .toFactory(viewModelsProductsMyProductsDetailPagesSupplyPointCustomerTransferViewModel.Factory);
  container
    .bind<interfaces.Factory<viewModelsProductsMyProductsDetailPagesSupplyPointModalEditSupplyPointViewModel>>(
      viewModelsProductsMyProductsDetailPagesSupplyPointModalEditSupplyPointViewModel.Factory
    )
    .toFactory(viewModelsProductsMyProductsDetailPagesSupplyPointModalEditSupplyPointViewModel.Factory);
  container
    .bind<viewModelsProductsMyProductsDetailPagesSupplyPointSupplyPointDetailPageViewModel>("IDetailPage")
    .to(viewModelsProductsMyProductsDetailPagesSupplyPointSupplyPointDetailPageViewModel);
}
