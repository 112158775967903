import type SupplyPointListItem from "entities/supplyPointListItem";
import type { ILocalizationService } from "@emanprague/shared-services";
import type { Router } from "@frui.ts/screens";
import { observer } from "mobx-react-lite";
import React from "react";
import { Card, Row, Col, Button } from "@emanprague/ppaskit";
import PaymentsDetailPageViewModel from "viewModels/products/myProducts/detailPages/paymentsDetailPageViewModel";
import AdvancesDetailPageViewModel from "viewModels/products/myProducts/detailPages/advancesDetailPageViewModel";
import InvoicesDetailPageViewModel from "viewModels/products/myProducts/detailPages/invoicesDetailPageViewModel";
import { ConditionalTooltip } from "components/ConditionalTooltip";
import ModalSelfReadingViewModel from "viewModels/products/myProducts/detailPages/history/modalSelfReadingViewModel";
import ProductDetailViewModel from "viewModels/products/myProducts/productDetailViewModel";
import iconStateNotOk from "@emanprague/ppaskit/dist/assets/icons/State/Not Ok.svg";
import iconStateOk from "@emanprague/ppaskit/dist/assets/icons/State/Ok.svg";
import { PinButton } from "components/PinButton";
import { runInAction } from "mobx";
import { StateLabel } from "components/StateLabel";

interface SupplyPointRowProps {
  supplyPoint: SupplyPointListItem;
  localization: ILocalizationService;
  router: Router;
  onToggle: (supplyPoint: SupplyPointListItem) => void;
}

export const SupplyPointRow: React.FunctionComponent<SupplyPointRowProps> = observer(
  ({ supplyPoint, localization, router, onToggle }) => {
    const { translateGeneral: tg, translateModel: tm } = localization;
    const translate = (code: string) => tg(`dashboard.supply_points.${code}`);
    const translateGeneral = (code: string) => tg(`general.${code}`);

    const addressText = supplyPoint.addressText.slice(0, supplyPoint.addressText.length - 7);
    const addressZip = supplyPoint.addressText.slice(supplyPoint.addressText.length - 7);

    const { change_deposit_amount, new_self_reading } = supplyPoint.settings ?? {};

    const toggleSupplyPoint = (pinned: boolean) => {
      runInAction(() => {
        supplyPoint.pinned = pinned;
      });
      onToggle(supplyPoint);
    };

    const stateText = (text: string) => {
      const code = supplyPoint.contract.code;
      return `${translate("contract_number")} ${code} ${translate("contract_is")} ${text}`;
    };

    return (
      <div className="px-sm-2">
        <Card key={supplyPoint.id} className="mb-3 px-2 px-sm-0">
          <Card.Body className="py-4">
            <Row className="align-items-center ">
              <Col xs={12} md={10} lg={5} className="mr-auto mb-md-2 mb-lg-0">
                <h4 className="display-4 mb-0" style={{}}>
                  {supplyPoint.name ?? (
                    <>
                      {addressText} <span style={{ whiteSpace: "nowrap" }}>{addressZip}</span>
                    </>
                  )}
                </h4>
                <div className="text-muted mb-2">
                  {addressText} <span style={{ whiteSpace: "nowrap" }}>{addressZip}</span>
                </div>

                <div className="py-sm-2">
                  {supplyPoint.contract.active ? (
                    <StateLabel
                      color={supplyPoint.state_color ?? "#00c34c"}
                      text={stateText(translateGeneral("active").toLowerCase())}
                    />
                  ) : (
                    <StateLabel
                      color={supplyPoint.state_color ?? "#0070cb"}
                      text={stateText(supplyPoint.state ?? translateGeneral("inactive"))}
                    />
                  )}
                </div>
              </Col>
              <Col xs={12} md="auto" className="my-4 mt-md-0 mb-3 mb-lg-0 mt-lg-1">
                {supplyPoint.dashboardNotice && (
                  // Not the best way. As soon as the notice gets used to anything else or disabled, this will need to be changed.
                  <>
                    <img className="pr-2" width={26} height={26} src={iconStateOk} alt="" />
                    <a
                      href={router.getUrl(PaymentsDetailPageViewModel, { supplyPointId: supplyPoint.id })}
                      className="color-blue-dark font-size-sm">
                      {tm("subsidy", 2)}
                    </a>
                  </>
                )}
                <img
                  className="ml-4 pr-2"
                  width={26}
                  height={26}
                  src={supplyPoint.depositDueCount === 0 ? iconStateOk : iconStateNotOk}
                  alt=""
                />
                <a
                  href={router.getUrl(AdvancesDetailPageViewModel, { supplyPointId: supplyPoint.id })}
                  className="color-blue-dark font-size-sm">
                  {tm("advance", 2)}
                </a>
                <img
                  className="ml-4 pr-2"
                  width={26}
                  height={26}
                  src={supplyPoint.invoiceDueCount === 0 ? iconStateOk : iconStateNotOk}
                  alt=""
                />
                <a
                  href={router.getUrl(InvoicesDetailPageViewModel, { supplyPointId: supplyPoint.id })}
                  className="color-blue-dark font-size-sm">
                  {tm("invoice", 2)}
                </a>
              </Col>
              <Col xs={12} lg="auto" className="ml-lg-auto ml-xl-3">
                <Row noGutters className="text-nowrap">
                  {supplyPoint.pinnable && (
                    <Col xs={6} md="auto" className="pr-1 pl-md-1">
                      <PinButton
                        className="my-1 mb-lg-0"
                        pinned={supplyPoint.pinned}
                        onToggle={toggleSupplyPoint}
                        pinnedText={translateGeneral("pinned")}
                        unpinnedText={translateGeneral("unpinned")}
                      />
                    </Col>
                  )}
                  <Col xs={6} md="auto" className="pr-1 pl-md-1">
                    <ConditionalTooltip
                      condition={!change_deposit_amount}
                      tooltip={translateGeneral("locked_action_tooltip")}
                      placement="top"
                      id="deposit_change_tooltip">
                      <Button
                        variant="secondary"
                        disabled={!change_deposit_amount}
                        className="my-1 mb-lg-0 w-100"
                        href={router.getUrl(AdvancesDetailPageViewModel, { supplyPointId: supplyPoint.id }, { change: true })}>
                        {translate("deposit_change")}
                      </Button>
                    </ConditionalTooltip>
                  </Col>
                  <Col xs={6} md="auto" className="pl-1 pr-md-1">
                    <ConditionalTooltip
                      condition={!new_self_reading}
                      tooltip={translateGeneral("locked_action_tooltip")}
                      placement="top"
                      id="self_reading_tooltip">
                      <Button
                        variant="secondary"
                        disabled={!new_self_reading}
                        className="my-1 mb-lg-0 w-100"
                        href={router.getUrl(ModalSelfReadingViewModel, { supplyPointId: supplyPoint.id })}>
                        {translate("button_self_reading")}
                      </Button>
                    </ConditionalTooltip>
                  </Col>
                  <Col xs={6} md="auto" className="pl-1">
                    <Button
                      variant="primary"
                      className="my-1 mb-lg-0 w-100"
                      href={router.getUrl(ProductDetailViewModel, { supplyPointId: supplyPoint.id })}>
                      {translate("detail")}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  }
);
