import type { ViewComponent } from "@frui.ts/views";
import { registerView } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Modal, SupplyPointListSelect, LoaderOverlay } from "@emanprague/ppaskit";
import ModalEditHistoricalSupplyPoints from "viewModels/products/myProducts/detailPages/customerInfo/modalEditHistoricalSupplyPoints";

const modalEditHistoricalSupplyPoints: ViewComponent<ModalEditHistoricalSupplyPoints> = observer(({ vm }) => (
  <Modal
    centered
    show
    onHide={async () => {
      // Prevent closing the modal when the request is in progress
      if (!vm.busyWatcher.isBusy) {
        await vm.requestClose();
      }
    }}>
    <Modal.Header closeButton>
      <Modal.Title>{vm.name}</Modal.Title>
    </Modal.Header>
    <LoaderOverlay loading={vm.busyWatcher.isBusy}>
      <Modal.Body>
        <SupplyPointListSelect
          supplyPoints={vm.supplyPointsWithIcons}
          selected={vm.selectedSupplyPoints}
          onToggle={vm.toggleSupplyPoint}
        />
        <div className="d-flex justify-content-between mt-3">
          <Button variant="outline-danger" onClick={vm.deselectAll}>
            {vm.translateGeneral("deselect_all")}
          </Button>
          <Button variant="outline-success" onClick={vm.selectAll}>
            {vm.translateGeneral("select_all")}
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={vm.requestClose}>
          {vm.translateGeneral("close_button")}
        </Button>
        <Button variant="primary" onClick={vm.confirm}>
          {vm.translateGeneral("confirm_changes_button")}
        </Button>
      </Modal.Footer>
    </LoaderOverlay>
  </Modal>
));

registerView(modalEditHistoricalSupplyPoints, ModalEditHistoricalSupplyPoints);
