import React from "react";

interface StateLabelProps {
  color?: string;
  text: string;
}

export const StateLabel: React.FC<StateLabelProps> = ({ color, text }) => {
  return (
    <>
      <span className="pr-2">
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="4" cy="4" r="4" fill={color} />
        </svg>
      </span>
      <span style={{ color: color }}>{text}</span>
    </>
  );
};
