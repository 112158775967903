import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class SupplyPointListItemAccount {
  id!: number;

  @observable
  @Expose({ name: "external_id" })
  externalId!: string;

  @observable
  @Expose({ name: "allow_to_change_sent_method" })
  allowToChangeSentMethod?: boolean;

  static ValidationRules = {
    id: { required: true, number: true },
  };
}
