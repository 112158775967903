import { Col, CrossSellBanner, Layout, Row, SupplyPointNav, Button } from "@emanprague/ppaskit";
import type { ViewComponent } from "@frui.ts/views";
import { registerView, View } from "@frui.ts/views";
import SapDashboardNotice from "components/SapDashboardNotice";
import SupplyPointsList from "controls/supplyPointsList";
import { getSupplyPointTypeIcon } from "helpers/supplyPointHelper";
import { observer } from "mobx-react-lite";
import React from "react";
import OverviewViewModel from "viewModels/supplyPoints/overviewViewModel";

const DetailView: ViewComponent<OverviewViewModel> = observer(({ vm }) => {
  if (!vm.activeChild) {
    return <React.Fragment />;
  }

  const { translateGeneral: tg } = vm.localization;

  const supplyPoints = vm.supplyPoints.map(x => ({
    id: x.id,
    title: x.name || x.addressText,
  }));

  const context = vm.activeChild.productDetailContext;

  return (
    <>
      <Row noGutters className="align-items-center pl-2 pr-3 pl-sm-0 py-2 py-md-3 mb-3">
        <Col xs={12} sm="auto" lg={12}>
          <SupplyPointNav
            currentTitle={vm.activeChild.activeChild?.name}
            rootHref={vm.router.getUrl(OverviewViewModel)}
            rootTitle={tg("supply_points.title")}
            selectSupplyPointLabel={tg("supply_points.detail.choose_point")}
            supplyPointIcon={getSupplyPointTypeIcon(context.commodityType)}
            supplyPointLabel={vm.activeSupplyPointLabel}
            supplyPoints={supplyPoints}
            supplyPointsOnChange={({ target }) => vm.navigate(target.value, undefined)}
            value={context.supplyPointId}
          />
        </Col>
      </Row>
      <View vm={vm.activeChild} />
    </>
  );
});

const overviewPageView: ViewComponent<OverviewViewModel> = observer(({ vm }) => {
  return (
    <>
      {vm.noticeText && <SapDashboardNotice text={vm.noticeText} onCancel={() => {}} />}
      <Layout fluid="xl" className="mt-3">
        {vm.activeChild ? (
          <DetailView vm={vm} />
        ) : (
          <>
            <SupplyPointsList
              supplyPointsByType={vm.supplyPointsByType}
              localization={vm.localization}
              router={vm.router}
              onToggle={vm.updateHistoricalSupplyPoint}
            />
            {vm.showHistoricalSupplyPointButton && (
              <div className="d-flex justify-content-center">
                <Button variant="secondary" href={vm.router.getUrl("customerAccount", undefined, { manageHistorical: true })}>
                  {vm.translate("manage_historical_supply_points")}
                </Button>
              </div>
            )}
            {vm.crossSellBanner && (
              <CrossSellBanner
                title={vm.crossSellBanner.title}
                htmlContent1={vm.crossSellBanner.text1}
                htmlContent2={vm.crossSellBanner.text2}
                links={vm.crossSellBanner.links}
                topCenterMessage={vm.translateGeneral("cross_sell.top_center_message")}
              />
            )}
          </>
        )}
      </Layout>
    </>
  );
});

registerView(overviewPageView, OverviewViewModel);
