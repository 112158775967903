import deburr from "lodash/deburr";
import React, { useState } from "react";
import { styleBlock } from "..";
import InputSearch from "../InputSearch";
import SupplyPointListItem, { SupplyPointLike } from "../SupplyPointListItem";

export interface SupplyPointListSelectProps {
  className?: string;
  onToggle: (id: number) => void;
  selected: number[];
  supplyPoints: SupplyPointLike[];
  search?: boolean;
}

const style = styleBlock("supply-point-list");

const SupplyPointListSelect: React.FunctionComponent<SupplyPointListSelectProps> = props => {
  const [filterValue, setFilterValue] = useState("");

  const searchString = filterValue && deburr(filterValue.toLowerCase());
  const matchesSearch = (attribute: string | undefined) => attribute && deburr(attribute.toLowerCase()).includes(searchString);
  const filteredSupplyPoints = searchString
    ? props.supplyPoints.filter(
        x =>
          matchesSearch(x.title) ||
          matchesSearch(x.address) ||
          matchesSearch(x.contractExternalId) ||
          matchesSearch(x.accountExternalId)
      )
    : props.supplyPoints;

  return (
    <div className={style.mix(props.className)}>
      {props.search && (
        <InputSearch
          type="text"
          size={"sm"}
          value={filterValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterValue(e.target.value)}
          placeholder={"Hledat název, adresu, číslo smlouvy nebo číslo účtu"}
        />
      )}
      <div className={style("container")}>
        {filteredSupplyPoints.map(item => (
          <SupplyPointListItem
            key={item.id}
            data={item}
            selected={props.selected.includes(item.id)}
            blue
            onClick={() => {
              props.onToggle(item.id);
            }}
          />
        ))}
      </div>
    </div>
  );
};

SupplyPointListSelect.defaultProps = {
  search: true,
} as Partial<SupplyPointListSelectProps>;

export default SupplyPointListSelect;
