import type { SupplyPointLike } from "@emanprague/ppaskit";
import iconElectricityYellow from "@emanprague/ppaskit/dist/assets/icons/Electricity Yellow.svg";
import iconElectricity from "@emanprague/ppaskit/dist/assets/icons/Electricity.svg";
import iconElectricityRed from "@emanprague/ppaskit/dist/assets/icons/Electricity Red.svg";
import iconElectricityDarkBlue from "@emanprague/ppaskit/dist/assets/icons/Electricity DarkBlue.svg";
import iconElectricityGrey from "@emanprague/ppaskit/dist/assets/icons/Electricity Grey.svg";
import iconGasBlue from "@emanprague/ppaskit/dist/assets/icons/Gas Blue.svg";
import iconGas from "@emanprague/ppaskit/dist/assets/icons/Gas.svg";
import iconGasRed from "@emanprague/ppaskit/dist/assets/icons/Gas Red.svg";
import iconGasDarkBlue from "@emanprague/ppaskit/dist/assets/icons/Gas DarkBlue.svg";
import iconGasGrey from "@emanprague/ppaskit/dist/assets/icons/Gas Grey.svg";
import type SupplyPoint from "entities/supplyPoint";
import type SupplyPointSimple from "entities/supplyPointSimple";
import CommodityType from "models/commodityType";
import type EnumsService from "services/enumsService";
import type SupplyPointListItem from "entities/supplyPointListItem";

export type IconColor = "default" | "yellowAndLightBlue" | "red" | "darkBlue" | "grey";
export type SupplyPointError = { id: number; status?: boolean; error: string };

export const getSupplyPointTypeIcon = (commodity?: CommodityType, color: IconColor = "default") => {
  switch (color) {
    case "yellowAndLightBlue":
      return commodity === CommodityType.Electricity ? iconElectricityYellow : iconGasBlue;
    case "red":
      return commodity === CommodityType.Electricity ? iconElectricityRed : iconGasRed;
    case "darkBlue":
      return commodity === CommodityType.Electricity ? iconElectricityDarkBlue : iconGasDarkBlue;
    case "grey":
      return commodity === CommodityType.Electricity ? iconElectricityGrey : iconGasGrey;
    default:
      return commodity === CommodityType.Electricity ? iconElectricity : iconGas;
  }
};

export const createSupplyPointsWithIcons = (
  enumsService: EnumsService,
  supplyPoints: (SupplyPoint | SupplyPointSimple | SupplyPointListItem)[],
  color: IconColor = "grey",
  errors?: SupplyPointError[]
): SupplyPointLike[] => {
  return supplyPoints.map(supplyPoint => ({
    id: supplyPoint.id,
    title: supplyPoint.name,
    address: supplyPoint.addressText,
    accountId: supplyPoint.accountId,
    icon: getSupplyPointTypeIcon(enumsService.getCommodityType(supplyPoint.commodityId), color),
    error: errors?.find(item => item.id === supplyPoint.id)?.error,
    contractExternalId: supplyPoint?.contract?.code,
    accountExternalId: supplyPoint?.account?.externalId,
  }));
};

export const isDoubleTariff = (tariff?: string) => {
  return !tariff?.startsWith("C0") && !tariff?.startsWith("D0");
};
