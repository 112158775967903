import { observer } from "mobx-react-lite";
import { Card, HeaderRow, Button } from "@emanprague/ppaskit";
import React from "react";
import { getSupplyPointTypeIcon } from "helpers/supplyPointHelper";
import iconEdit from "@emanprague/ppaskit/dist/assets/icons/Edit.svg";
import iconInfo from "@emanprague/ppaskit/dist/assets/icons/Info.svg";
import type CustomerAccountViewModel from "viewModels/dashboard/customerAccountViewModel";
import Tooltip from "@emanprague/basekit/dist/Tooltip";

interface HistoricalSupplyPointsCardViewProps {
  vm: CustomerAccountViewModel;
}

export const HistoricalSupplyPointsCard: React.FunctionComponent<HistoricalSupplyPointsCardViewProps> = observer(({ vm }) => (
  <Card>
    <Card.Body>
      <HeaderRow title={vm.translate("historical_supply_point.title")} level={3}>
        <Button variant="secondary" size="sm" onClick={vm.activateUpdateHistoricalSupplyPoints}>
          <img src={iconEdit} width={16} height={16} alt="" className="mr-2" />
          {vm.translateGeneral("edit_button")}
        </Button>
      </HeaderRow>
      {vm.pinnedSupplyPoints.length === 0 ? (
        <div className="text-center">{vm.translate("historical_supply_point.no_data")}</div>
      ) : (
        <table className="table responsive-col2row font-size-sm wider-row">
          <thead>
            <tr>
              <th>{vm.translate("historical_supply_point.supply_point")}</th>
              <th>{vm.translate("historical_supply_point.contract_number")}</th>
              <th className="cell-right">
                <>
                  {vm.translate("historical_supply_point.pinned_until")}
                  &nbsp;
                  <Tooltip
                    id="pinnedUntilTooltip"
                    tooltip={vm.translate("historical_supply_point.pinned_until_tooltip")}
                    placement="auto">
                    <img src={iconInfo} alt="" width={22} height={22} className="align-top" />
                  </Tooltip>
                </>
              </th>
            </tr>
          </thead>
          <tbody>
            {vm.pinnedSupplyPoints.map(item => (
              <tr key={`sp-${item.id}-${item.id}`}>
                <td data-title={vm.localization.translateModel("supply_point", 1)}>
                  <div className="d-flex align-items-center">
                    <div className="mr-2">
                      <img
                        width="22"
                        height="21"
                        draggable={false}
                        src={getSupplyPointTypeIcon(vm.enumsService.getCommodityType(item.commodityId), "yellowAndLightBlue")}
                        alt=""
                      />
                    </div>
                    <div className="text-nowrap">
                      <span>{item.name}</span>
                      <div>{item.addressText}</div>
                    </div>
                  </div>
                </td>
                <td>{item.contract.code}</td>
                <td className="cell-right">{vm.localization.formatDate(item.pinnedUntil)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Card.Body>
  </Card>
));
