// WARNING: This file has been generated. Do not edit it manually, your changes might get lost.
import { decorate, inject, injectable, multiInject } from "inversify";
import nodeModulesFruiTsScreensDistNavigationRouter from "../node_modules/@frui.ts/screens/dist/navigation/router";
import modelsProductDetailContext from "./models/productDetailContext";
import repositoriesAccountsRepository from "./repositories/accountsRepository";
import repositoriesAdminRepository from "./repositories/adminRepository";
import repositoriesAdvancesRepository from "./repositories/advancesRepository";
import repositoriesAttachmentsRepository from "./repositories/attachmentsRepository";
import repositoriesBackendConnector from "./repositories/backendConnector";
import repositoriesContractRepository from "./repositories/contractRepository";
import repositoriesEnumsRepository from "./repositories/enumsRepository";
import repositoriesGeneralSettingsRepository from "./repositories/generalSettingsRepository";
import repositoriesHistoricalSupplyPointsRepository from "./repositories/historicalSupplyPointsRepository";
import repositoriesInvoicesRepository from "./repositories/invoicesRepository";
import repositoriesLinksRepository from "./repositories/linksRepository";
import repositoriesLoginRepository from "./repositories/loginRepository";
import repositoriesMessagesRepository from "./repositories/messagesRepository";
import repositoriesNotificationsRepository from "./repositories/notificationsRepository";
import repositoriesPartnersRepository from "./repositories/partnersRepository";
import repositoriesPaymentsRepository from "./repositories/paymentsRepository";
import repositoriesPortalSettingsRepository from "./repositories/portalSettingsRepository";
import repositoriesProductCardsRepository from "./repositories/productCardsRepository";
import repositoriesReadingsRepository from "./repositories/readingsRepository";
import repositoriesRequestsRepository from "./repositories/requestsRepository";
import repositoriesRuianRepository from "./repositories/ruianRepository";
import repositoriesSupplyPointsRepository from "./repositories/supplyPointsRepository";
import servicesAppendixesService from "./services/appendixesService";
import servicesBannersService from "./services/bannersService";
import servicesConfirmationService from "./services/confirmationService";
import servicesDataSyncService from "./services/dataSyncService";
import servicesDownloadingService from "./services/downloadingService";
import servicesEnumsService from "./services/enumsService";
import { EventsService as servicesEventsEventsService } from "./services/events";
import servicesGaService from "./services/gaService";
import servicesHistoricalSupplyPointsService from "./services/historicalSupplyPointsService";
import servicesInitializationService from "./services/initializationService";
import servicesLocaleProvider from "./services/localeProvider";
import servicesMessagesService from "./services/messagesService";
import servicesNotificationService from "./services/notificationService";
import servicesPortalSettingsService from "./services/portalSettingsService";
import servicesProductCardsService from "./services/productCardsService";
import servicesRequestsService from "./services/requestsService";
import servicesSecurityService from "./services/securityService";
import servicesServerCommunicationProvider from "./services/serverCommunicationProvider";
import servicesUserContext from "./services/userContext";
import servicesValidationPasswordValidationService from "./services/validation/passwordValidationService";
import servicesValidationValidationService from "./services/validation/validationService";
import viewModelsAddressChangeViewModel from "./viewModels/addressChangeViewModel";
import viewModelsAdminAdminCreateViewModel from "./viewModels/admin/adminCreateViewModel";
import viewModelsAdminAdminsPageViewModel from "./viewModels/admin/adminsPageViewModel";
import viewModelsAdminAdminUpdateViewModel from "./viewModels/admin/adminUpdateViewModel";
import viewModelsAdminAuditLogsPageViewModel from "./viewModels/admin/auditLogsPageViewModel";
import viewModelsAdminBannerDetailViewModel from "./viewModels/admin/bannerDetailViewModel";
import viewModelsAdminBannerPreviewViewModel from "./viewModels/admin/bannerPreviewViewModel";
import viewModelsAdminBannersPageViewModel from "./viewModels/admin/bannersPageViewModel";
import viewModelsAdminGeneralSettingsPageViewModel from "./viewModels/admin/generalSettingsPageViewModel";
import viewModelsAdminLinksDetailViewModel from "./viewModels/admin/linksDetailViewModel";
import viewModelsAdminLinksSettingsPageViewModel from "./viewModels/admin/linksSettingsPageViewModel";
import viewModelsAdminMessageDetailViewModel from "./viewModels/admin/messageDetailViewModel";
import viewModelsAdminMessagePreviewViewModel from "./viewModels/admin/messagePreviewViewModel";
import viewModelsAdminMessagesPageViewModel from "./viewModels/admin/messagesPageViewModel";
import viewModelsAdminNoticeSettingsPageViewModel from "./viewModels/admin/noticeSettingsPageViewModel";
import viewModelsAdminProductCardsProductCardDetailViewModel from "./viewModels/admin/productCards/productCardDetailViewModel";
import viewModelsAdminProductCardsProductCardPreviewViewModel from "./viewModels/admin/productCards/productCardPreviewViewModel";
import viewModelsAdminProductCardsProductCardsPageViewModel from "./viewModels/admin/productCards/productCardsPageViewModel";
import viewModelsAdminScreenSharePageViewModel from "./viewModels/admin/screenSharePageViewModel";
import viewModelsAdminRootViewModel from "./viewModels/adminRootViewModel";
import viewModelsAnonymousRootViewModel from "./viewModels/anonymousRootViewModel";
import viewModelsConfirmDialogViewModel from "./viewModels/confirmDialogViewModel";
import viewModelsDashboardAttachmentsAttachmentCreateViewModel from "./viewModels/dashboard/attachments/attachmentCreateViewModel";
import viewModelsDashboardAttachmentsAttachmentsViewModel from "./viewModels/dashboard/attachments/attachmentsViewModel";
import viewModelsDashboardAttachmentsAttachmentUpdateViewModel from "./viewModels/dashboard/attachments/attachmentUpdateViewModel";
import viewModelsDashboardCustomerAccountViewModel from "./viewModels/dashboard/customerAccountViewModel";
import viewModelsDashboardDashboardViewModel from "./viewModels/dashboard/dashboardViewModel";
import viewModelsDashboardOverviewViewModel from "./viewModels/dashboard/overviewViewModel";
import viewModelsDashboardPartnerConfirmViewModel from "./viewModels/dashboard/partnerConfirmViewModel";
import viewModelsDashboardPartnerConnectViewModel from "./viewModels/dashboard/partnerConnectViewModel";
import viewModelsDashboardPartnerRemoveViewModel from "./viewModels/dashboard/partnerRemoveViewModel";
import viewModelsDashboardWidgetsSupplyPointsViewModel from "./viewModels/dashboard/widgets/supplyPointsViewModel";
import viewModelsFinanceAdvanceChangeViewModel from "./viewModels/finance/advanceChangeViewModel";
import viewModelsFinanceAdvanceDetailViewModel from "./viewModels/finance/advanceDetailViewModel";
import viewModelsFinanceAdvancesPageViewModel from "./viewModels/finance/advancesPageViewModel";
import viewModelsFinanceInvoiceComplaintViewModel from "./viewModels/finance/invoiceComplaintViewModel";
import viewModelsFinanceInvoiceDetailViewModel from "./viewModels/finance/invoiceDetailViewModel";
import viewModelsFinanceInvoicesPageViewModel from "./viewModels/finance/invoicesPageViewModel";
import viewModelsFinanceOnlinePaymentViewModel from "./viewModels/finance/onlinePaymentViewModel";
import viewModelsFinanceOverviewViewModel from "./viewModels/finance/overviewViewModel";
import viewModelsFinancePaymentsPageViewModel from "./viewModels/finance/paymentsPageViewModel";
import viewModelsMessagesOverviewViewModel from "./viewModels/messages/overviewViewModel";
import viewModelsProductsAppendixesPageViewModel from "./viewModels/products/appendixesPageViewModel";
import viewModelsProductsAxaPageViewModel from "./viewModels/products/axaPageViewModel";
import viewModelsProductsCngPageViewModel from "./viewModels/products/cngPageViewModel";
import viewModelsProductsCustomerCardPageViewModel from "./viewModels/products/customerCardPageViewModel";
import viewModelsProductsMyProductsDetailPagesAdvancesDetailPageViewModel from "./viewModels/products/myProducts/detailPages/advancesDetailPageViewModel";
import viewModelsProductsMyProductsDetailPagesContractContractDetailPageViewModel from "./viewModels/products/myProducts/detailPages/contract/contractDetailPageViewModel";
import viewModelsProductsMyProductsDetailPagesContractModalDocumentsViewModel from "./viewModels/products/myProducts/detailPages/contract/modalDocumentsViewModel";
import viewModelsProductsMyProductsDetailPagesCustomerInfoCustomerInfoDetailPageViewModel from "./viewModels/products/myProducts/detailPages/customerInfo/customerInfoDetailPageViewModel";
import viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditContactInfoViewModel from "./viewModels/products/myProducts/detailPages/customerInfo/modalEditContactInfoViewModel";
import viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditCustomerInfoViewModel from "./viewModels/products/myProducts/detailPages/customerInfo/modalEditCustomerInfoViewModel";
import viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditHistoricalSupplyPoints from "./viewModels/products/myProducts/detailPages/customerInfo/modalEditHistoricalSupplyPoints";
import viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditMailingAddressViewModel from "./viewModels/products/myProducts/detailPages/customerInfo/modalEditMailingAddressViewModel";
import viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditMarketingAgreementViewModel from "./viewModels/products/myProducts/detailPages/customerInfo/modalEditMarketingAgreementViewModel";
import viewModelsProductsMyProductsDetailPagesHistoryBulkSelfReadingViewModel from "./viewModels/products/myProducts/detailPages/history/bulkSelfReadingViewModel";
import viewModelsProductsMyProductsDetailPagesHistoryChartViewModel from "./viewModels/products/myProducts/detailPages/history/chartViewModel";
import viewModelsProductsMyProductsDetailPagesHistoryHistoryDetailPageViewModel from "./viewModels/products/myProducts/detailPages/history/historyDetailPageViewModel";
import viewModelsProductsMyProductsDetailPagesHistoryModalSelfReadingViewModel from "./viewModels/products/myProducts/detailPages/history/modalSelfReadingViewModel";
import viewModelsProductsMyProductsDetailPagesHistorySingleSelfReadingViewModel from "./viewModels/products/myProducts/detailPages/history/singleSelfReadingViewModel";
import viewModelsProductsMyProductsDetailPagesHistoryTableViewModel from "./viewModels/products/myProducts/detailPages/history/tableViewModel";
import viewModelsProductsMyProductsDetailPagesInvoicesDetailPageViewModel from "./viewModels/products/myProducts/detailPages/invoicesDetailPageViewModel";
import viewModelsProductsMyProductsDetailPagesOptionsDepositModalAdvancePaymentMethodViewModel from "./viewModels/products/myProducts/detailPages/optionsDeposit/modalAdvancePaymentMethodViewModel";
import viewModelsProductsMyProductsDetailPagesOptionsDepositModalInvoiceMailingMethodViewModel from "./viewModels/products/myProducts/detailPages/optionsDeposit/modalInvoiceMailingMethodViewModel";
import viewModelsProductsMyProductsDetailPagesOptionsDepositModalInvoicePaymentMethodViewModel from "./viewModels/products/myProducts/detailPages/optionsDeposit/modalInvoicePaymentMethodViewModel";
import viewModelsProductsMyProductsDetailPagesOptionsDepositOptionsDepositDetailPageViewModel from "./viewModels/products/myProducts/detailPages/optionsDeposit/optionsDepositDetailPageViewModel";
import viewModelsProductsMyProductsDetailPagesPaymentsDetailPageViewModel from "./viewModels/products/myProducts/detailPages/paymentsDetailPageViewModel";
import viewModelsProductsMyProductsDetailPagesSupplyPointCustomerTransferViewModel from "./viewModels/products/myProducts/detailPages/supplyPoint/customerTransferViewModel";
import viewModelsProductsMyProductsDetailPagesSupplyPointModalEditSupplyPointViewModel from "./viewModels/products/myProducts/detailPages/supplyPoint/modalEditSupplyPointViewModel";
import viewModelsProductsMyProductsDetailPagesSupplyPointSupplyPointDetailPageViewModel from "./viewModels/products/myProducts/detailPages/supplyPoint/supplyPointDetailPageViewModel";
import viewModelsProductsMyProductsProductDetailViewModel from "./viewModels/products/myProducts/productDetailViewModel";
import viewModelsProductsOtherProductsPageViewModel from "./viewModels/products/otherProductsPageViewModel";
import viewModelsProductsOverviewViewModel from "./viewModels/products/overviewViewModel";
import viewModelsProfileCompetitionsViewModel from "./viewModels/profile/competitionsViewModel";
import viewModelsProfileConfirmRegistrationViewModel from "./viewModels/profile/confirmRegistrationViewModel";
import viewModelsProfileForgottenPasswordViewModel from "./viewModels/profile/forgottenPasswordViewModel";
import viewModelsProfileLoginViewModel from "./viewModels/profile/loginViewModel";
import viewModelsProfileRefreshCredentialsViewModel from "./viewModels/profile/refreshCredentialsViewModel";
import viewModelsProfileRegistrationViewModel from "./viewModels/profile/registrationViewModel";
import viewModelsProfileResetPasswordViewModel from "./viewModels/profile/resetPasswordViewModel";
import viewModelsProfileStatusViewModel from "./viewModels/profile/statusViewModel";
import viewModelsProfileUpdateEmailViewModel from "./viewModels/profile/updateEmailViewModel";
import viewModelsProfileUpdatePasswordViewModel from "./viewModels/profile/updatePasswordViewModel";
import viewModelsRequestsModalCreateRequestViewModel from "./viewModels/requests/modalCreateRequestViewModel";
import viewModelsRequestsOverviewViewModel from "./viewModels/requests/overviewViewModel";
import viewModelsRootViewModel from "./viewModels/rootViewModel";
import viewModelsSupplyPointsOverviewViewModel from "./viewModels/supplyPoints/overviewViewModel";
import viewModelsToastViewModel from "./viewModels/toastViewModel";
decorate(injectable(), repositoriesAccountsRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesAccountsRepository, 0);
decorate(inject("IEventBus") as any, repositoriesAccountsRepository, 1);
decorate(injectable(), repositoriesAdminRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesAdminRepository, 0);
decorate(inject("IEventBus") as any, repositoriesAdminRepository, 1);
decorate(injectable(), repositoriesAdvancesRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesAdvancesRepository, 0);
decorate(inject("IEventBus") as any, repositoriesAdvancesRepository, 1);
decorate(injectable(), repositoriesAttachmentsRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesAttachmentsRepository, 0);
decorate(inject("IEventBus") as any, repositoriesAttachmentsRepository, 1);
decorate(injectable(), repositoriesContractRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesContractRepository, 0);
decorate(inject("IEventBus") as any, repositoriesContractRepository, 1);
decorate(injectable(), repositoriesEnumsRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesEnumsRepository, 0);
decorate(inject("IEventBus") as any, repositoriesEnumsRepository, 1);
decorate(injectable(), repositoriesGeneralSettingsRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesGeneralSettingsRepository, 0);
decorate(inject("IEventBus") as any, repositoriesGeneralSettingsRepository, 1);
decorate(injectable(), repositoriesHistoricalSupplyPointsRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesHistoricalSupplyPointsRepository, 0);
decorate(inject("IEventBus") as any, repositoriesHistoricalSupplyPointsRepository, 1);
decorate(injectable(), repositoriesInvoicesRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesInvoicesRepository, 0);
decorate(inject("IEventBus") as any, repositoriesInvoicesRepository, 1);
decorate(injectable(), repositoriesLinksRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesLinksRepository, 0);
decorate(inject("IEventBus") as any, repositoriesLinksRepository, 1);
decorate(injectable(), repositoriesLoginRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesLoginRepository, 0);
decorate(inject("IEventBus") as any, repositoriesLoginRepository, 1);
decorate(injectable(), repositoriesMessagesRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesMessagesRepository, 0);
decorate(inject("IEventBus") as any, repositoriesMessagesRepository, 1);
decorate(injectable(), repositoriesNotificationsRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesNotificationsRepository, 0);
decorate(inject("IEventBus") as any, repositoriesNotificationsRepository, 1);
decorate(injectable(), repositoriesPartnersRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesPartnersRepository, 0);
decorate(inject("IEventBus") as any, repositoriesPartnersRepository, 1);
decorate(injectable(), repositoriesPaymentsRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesPaymentsRepository, 0);
decorate(inject("IEventBus") as any, repositoriesPaymentsRepository, 1);
decorate(injectable(), repositoriesPortalSettingsRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesPortalSettingsRepository, 0);
decorate(inject("IEventBus") as any, repositoriesPortalSettingsRepository, 1);
decorate(injectable(), repositoriesProductCardsRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesProductCardsRepository, 0);
decorate(inject("IEventBus") as any, repositoriesProductCardsRepository, 1);
decorate(injectable(), repositoriesReadingsRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesReadingsRepository, 0);
decorate(inject("IEventBus") as any, repositoriesReadingsRepository, 1);
decorate(injectable(), repositoriesRequestsRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesRequestsRepository, 0);
decorate(inject("IEventBus") as any, repositoriesRequestsRepository, 1);
decorate(injectable(), repositoriesRuianRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesRuianRepository, 0);
decorate(inject("IEventBus") as any, repositoriesRuianRepository, 1);
decorate(injectable(), repositoriesSupplyPointsRepository);
decorate(inject(repositoriesBackendConnector) as any, repositoriesSupplyPointsRepository, 0);
decorate(inject("IEventBus") as any, repositoriesSupplyPointsRepository, 1);
decorate(injectable(), servicesAppendixesService);
decorate(inject(repositoriesContractRepository) as any, servicesAppendixesService, 0);
decorate(inject("IEventBus") as any, servicesAppendixesService, 1);
decorate(inject(servicesUserContext) as any, servicesAppendixesService, 2);
decorate(injectable(), servicesBannersService);
decorate(inject(servicesUserContext) as any, servicesBannersService, 0);
decorate(inject(repositoriesPartnersRepository) as any, servicesBannersService, 1);
decorate(injectable(), servicesConfirmationService);
decorate(inject("ILocalizationService") as any, servicesConfirmationService, 0);
decorate(injectable(), servicesDataSyncService);
decorate(inject(servicesUserContext) as any, servicesDataSyncService, 0);
decorate(inject(servicesServerCommunicationProvider) as any, servicesDataSyncService, 1);
decorate(inject(repositoriesLoginRepository) as any, servicesDataSyncService, 2);
decorate(inject(repositoriesSupplyPointsRepository) as any, servicesDataSyncService, 3);
decorate(inject(repositoriesPartnersRepository) as any, servicesDataSyncService, 4);
decorate(inject("IEventBus") as any, servicesDataSyncService, 5);
decorate(inject(servicesProductCardsService) as any, servicesDataSyncService, 6);
decorate(injectable(), servicesDownloadingService);
decorate(inject("IEventBus") as any, servicesDownloadingService, 0);
decorate(inject(repositoriesNotificationsRepository) as any, servicesDownloadingService, 1);
decorate(inject(servicesUserContext) as any, servicesDownloadingService, 2);
decorate(injectable(), servicesEnumsService);
decorate(inject(repositoriesEnumsRepository) as any, servicesEnumsService, 0);
decorate(injectable(), servicesEventsEventsService);
decorate(injectable(), servicesGaService);
decorate(injectable(), servicesHistoricalSupplyPointsService);
decorate(inject(servicesUserContext) as any, servicesHistoricalSupplyPointsService, 0);
decorate(inject("INotificationService") as any, servicesHistoricalSupplyPointsService, 1);
decorate(inject(repositoriesHistoricalSupplyPointsRepository) as any, servicesHistoricalSupplyPointsService, 2);
decorate(inject("ILocalizationService") as any, servicesHistoricalSupplyPointsService, 3);
decorate(injectable(), servicesInitializationService);
decorate(inject(servicesUserContext) as any, servicesInitializationService, 0);
decorate(inject("ILocalizationService") as any, servicesInitializationService, 1);
decorate(inject(servicesValidationValidationService) as any, servicesInitializationService, 2);
decorate(inject(servicesSecurityService) as any, servicesInitializationService, 3);
decorate(inject(servicesEnumsService) as any, servicesInitializationService, 4);
decorate(inject(servicesPortalSettingsService) as any, servicesInitializationService, 5);
decorate(inject(servicesDataSyncService) as any, servicesInitializationService, 6);
decorate(inject(servicesBannersService) as any, servicesInitializationService, 7);
decorate(inject(servicesMessagesService) as any, servicesInitializationService, 8);
decorate(inject(servicesGaService) as any, servicesInitializationService, 9);
decorate(inject(servicesAppendixesService) as any, servicesInitializationService, 10);
decorate(injectable(), servicesLocaleProvider);
decorate(inject(repositoriesBackendConnector) as any, servicesLocaleProvider, 0);
decorate(inject("IEventBus") as any, servicesLocaleProvider, 1);
decorate(injectable(), servicesMessagesService);
decorate(inject(servicesUserContext) as any, servicesMessagesService, 0);
decorate(inject(repositoriesMessagesRepository) as any, servicesMessagesService, 1);
decorate(injectable(), servicesNotificationService);
decorate(inject("IEventBus") as any, servicesNotificationService, 0);
decorate(injectable(), servicesPortalSettingsService);
decorate(inject(repositoriesPortalSettingsRepository) as any, servicesPortalSettingsService, 0);
decorate(inject("INotificationService") as any, servicesPortalSettingsService, 1);
decorate(inject("ILocalizationService") as any, servicesPortalSettingsService, 2);
decorate(injectable(), servicesProductCardsService);
decorate(inject(servicesUserContext) as any, servicesProductCardsService, 0);
decorate(inject(repositoriesProductCardsRepository) as any, servicesProductCardsService, 1);
decorate(injectable(), servicesRequestsService);
decorate(inject(repositoriesRequestsRepository) as any, servicesRequestsService, 0);
decorate(inject("IEventBus") as any, servicesRequestsService, 1);
decorate(injectable(), servicesSecurityService);
decorate(inject(repositoriesLoginRepository) as any, servicesSecurityService, 0);
decorate(inject(servicesUserContext) as any, servicesSecurityService, 1);
decorate(inject("IEventBus") as any, servicesSecurityService, 2);
decorate(inject(repositoriesAdminRepository) as any, servicesSecurityService, 3);
decorate(injectable(), servicesServerCommunicationProvider);
decorate(injectable(), viewModelsAddressChangeViewModel);
decorate(injectable(), viewModelsAdminRootViewModel);
decorate(multiInject("IAdminModule") as any, viewModelsAdminRootViewModel, 0);
decorate(inject(servicesUserContext) as any, viewModelsAdminRootViewModel, 1);
decorate(inject("INotificationService") as any, viewModelsAdminRootViewModel, 2);
decorate(inject(servicesSecurityService) as any, viewModelsAdminRootViewModel, 3);
decorate(inject("ILocalizationService") as any, viewModelsAdminRootViewModel, 4);
decorate(inject(servicesConfirmationService) as any, viewModelsAdminRootViewModel, 5);
decorate(inject("IEventBus") as any, viewModelsAdminRootViewModel, 6);
decorate(inject("Factory<RefreshCredentialsViewModel>") as any, viewModelsAdminRootViewModel, 7);
decorate(injectable(), viewModelsAnonymousRootViewModel);
decorate(inject("ILocalizationService") as any, viewModelsAnonymousRootViewModel, 0);
decorate(inject(viewModelsProfileLoginViewModel) as any, viewModelsAnonymousRootViewModel, 1);
decorate(inject("Factory<RegistrationViewModel>") as any, viewModelsAnonymousRootViewModel, 2);
decorate(inject("Factory<ForgottenPasswordViewModel>") as any, viewModelsAnonymousRootViewModel, 3);
decorate(inject("Factory<ConfirmRegistrationViewModel>") as any, viewModelsAnonymousRootViewModel, 4);
decorate(inject("Factory<ResetPasswordViewModel>") as any, viewModelsAnonymousRootViewModel, 5);
decorate(inject("Factory<PartnerConfirmViewModel>") as any, viewModelsAnonymousRootViewModel, 6);
decorate(inject(repositoriesLoginRepository) as any, viewModelsAnonymousRootViewModel, 7);
decorate(inject(servicesPortalSettingsService) as any, viewModelsAnonymousRootViewModel, 8);
decorate(inject("INotificationService") as any, viewModelsAnonymousRootViewModel, 9);
decorate(injectable(), viewModelsConfirmDialogViewModel);
decorate(inject("ButtonParams") as any, viewModelsConfirmDialogViewModel, 2);
decorate(inject("ButtonParams") as any, viewModelsConfirmDialogViewModel, 3);
decorate(inject("ConfirmDialogSettings") as any, viewModelsConfirmDialogViewModel, 4);
decorate(injectable(), viewModelsRootViewModel);
decorate(multiInject("IModule") as any, viewModelsRootViewModel, 0);
decorate(inject("INotificationService") as any, viewModelsRootViewModel, 1);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsRootViewModel, 2);
decorate(inject(viewModelsProfileStatusViewModel) as any, viewModelsRootViewModel, 3);
decorate(inject("Factory<UpdatePasswordViewModel>") as any, viewModelsRootViewModel, 4);
decorate(inject(viewModelsProductsAppendixesPageViewModel) as any, viewModelsRootViewModel, 5);
decorate(inject(viewModelsProfileCompetitionsViewModel) as any, viewModelsRootViewModel, 6);
decorate(inject("Factory<RefreshCredentialsViewModel>") as any, viewModelsRootViewModel, 7);
decorate(inject(repositoriesLoginRepository) as any, viewModelsRootViewModel, 8);
decorate(inject("ILocalizationService") as any, viewModelsRootViewModel, 9);
decorate(inject("Factory<PartnerRemoveViewModel>") as any, viewModelsRootViewModel, 10);
decorate(inject("Factory<PartnerConfirmViewModel>") as any, viewModelsRootViewModel, 11);
decorate(inject(servicesSecurityService) as any, viewModelsRootViewModel, 12);
decorate(inject("IEventBus") as any, viewModelsRootViewModel, 13);
decorate(inject(servicesEnumsService) as any, viewModelsRootViewModel, 14);
decorate(inject(servicesConfirmationService) as any, viewModelsRootViewModel, 15);
decorate(inject(servicesMessagesService) as any, viewModelsRootViewModel, 16);
decorate(inject(servicesAppendixesService) as any, viewModelsRootViewModel, 17);
decorate(inject(servicesUserContext) as any, viewModelsRootViewModel, 18);
decorate(injectable(), viewModelsToastViewModel);
decorate(injectable(), servicesValidationPasswordValidationService);
decorate(injectable(), servicesValidationValidationService);
decorate(inject("ILocalizationService") as any, servicesValidationValidationService, 0);
decorate(injectable(), viewModelsAdminAdminCreateViewModel);
decorate(injectable(), viewModelsAdminAdminsPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsAdminAdminsPageViewModel, 0);
decorate(inject(viewModelsAdminAdminCreateViewModel.Factory) as any, viewModelsAdminAdminsPageViewModel, 1);
decorate(inject(viewModelsAdminAdminUpdateViewModel.Factory) as any, viewModelsAdminAdminsPageViewModel, 2);
decorate(inject(repositoriesAdminRepository) as any, viewModelsAdminAdminsPageViewModel, 3);
decorate(inject(servicesEnumsService) as any, viewModelsAdminAdminsPageViewModel, 4);
decorate(injectable(), viewModelsAdminAdminUpdateViewModel);
decorate(injectable(), viewModelsAdminAuditLogsPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsAdminAuditLogsPageViewModel, 0);
decorate(inject(repositoriesAdminRepository) as any, viewModelsAdminAuditLogsPageViewModel, 1);
decorate(inject(servicesEnumsService) as any, viewModelsAdminAuditLogsPageViewModel, 2);
decorate(inject("INotificationService") as any, viewModelsAdminAuditLogsPageViewModel, 3);
decorate(injectable(), viewModelsAdminBannerDetailViewModel);
decorate(injectable(), viewModelsAdminBannerPreviewViewModel);
decorate(injectable(), viewModelsAdminBannersPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsAdminBannersPageViewModel, 0);
decorate(inject(viewModelsAdminBannerDetailViewModel.Factory) as any, viewModelsAdminBannersPageViewModel, 1);
decorate(inject(viewModelsAdminBannerPreviewViewModel.Factory) as any, viewModelsAdminBannersPageViewModel, 2);
decorate(inject(repositoriesAdminRepository) as any, viewModelsAdminBannersPageViewModel, 3);
decorate(inject(servicesConfirmationService) as any, viewModelsAdminBannersPageViewModel, 4);
decorate(inject("INotificationService") as any, viewModelsAdminBannersPageViewModel, 5);
decorate(injectable(), viewModelsAdminGeneralSettingsPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsAdminGeneralSettingsPageViewModel, 0);
decorate(inject(repositoriesGeneralSettingsRepository) as any, viewModelsAdminGeneralSettingsPageViewModel, 1);
decorate(inject(servicesPortalSettingsService) as any, viewModelsAdminGeneralSettingsPageViewModel, 2);
decorate(inject(servicesConfirmationService) as any, viewModelsAdminGeneralSettingsPageViewModel, 3);
decorate(injectable(), viewModelsAdminLinksDetailViewModel);
decorate(injectable(), viewModelsAdminLinksSettingsPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsAdminLinksSettingsPageViewModel, 0);
decorate(inject(viewModelsAdminLinksDetailViewModel.Factory) as any, viewModelsAdminLinksSettingsPageViewModel, 1);
decorate(inject(repositoriesLinksRepository) as any, viewModelsAdminLinksSettingsPageViewModel, 2);
decorate(inject("IEventBus") as any, viewModelsAdminLinksSettingsPageViewModel, 3);
decorate(injectable(), viewModelsAdminMessageDetailViewModel);
decorate(injectable(), viewModelsAdminMessagePreviewViewModel);
decorate(injectable(), viewModelsAdminMessagesPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsAdminMessagesPageViewModel, 0);
decorate(inject(viewModelsAdminMessageDetailViewModel.Factory) as any, viewModelsAdminMessagesPageViewModel, 1);
decorate(inject(viewModelsAdminMessagePreviewViewModel.Factory) as any, viewModelsAdminMessagesPageViewModel, 2);
decorate(inject(repositoriesAdminRepository) as any, viewModelsAdminMessagesPageViewModel, 3);
decorate(inject(servicesConfirmationService) as any, viewModelsAdminMessagesPageViewModel, 4);
decorate(inject("INotificationService") as any, viewModelsAdminMessagesPageViewModel, 5);
decorate(injectable(), viewModelsAdminNoticeSettingsPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsAdminNoticeSettingsPageViewModel, 0);
decorate(inject(servicesPortalSettingsService) as any, viewModelsAdminNoticeSettingsPageViewModel, 1);
decorate(injectable(), viewModelsAdminScreenSharePageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsAdminScreenSharePageViewModel, 0);
decorate(inject(servicesEnumsService) as any, viewModelsAdminScreenSharePageViewModel, 1);
decorate(inject(repositoriesAdminRepository) as any, viewModelsAdminScreenSharePageViewModel, 2);
decorate(injectable(), viewModelsDashboardCustomerAccountViewModel);
decorate(inject("ILocalizationService") as any, viewModelsDashboardCustomerAccountViewModel, 0);
decorate(inject(servicesUserContext) as any, viewModelsDashboardCustomerAccountViewModel, 1);
decorate(inject(servicesDataSyncService) as any, viewModelsDashboardCustomerAccountViewModel, 2);
decorate(inject(servicesEnumsService) as any, viewModelsDashboardCustomerAccountViewModel, 3);
decorate(inject(servicesHistoricalSupplyPointsService) as any, viewModelsDashboardCustomerAccountViewModel, 4);
decorate(
  inject(viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditCustomerInfoViewModel.Factory) as any,
  viewModelsDashboardCustomerAccountViewModel,
  5
);
decorate(
  inject(viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditContactInfoViewModel.Factory) as any,
  viewModelsDashboardCustomerAccountViewModel,
  6
);
decorate(
  inject(viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditMarketingAgreementViewModel.Factory) as any,
  viewModelsDashboardCustomerAccountViewModel,
  7
);
decorate(
  inject(viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditMailingAddressViewModel.Factory) as any,
  viewModelsDashboardCustomerAccountViewModel,
  8
);
decorate(
  inject(viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditHistoricalSupplyPoints.Factory) as any,
  viewModelsDashboardCustomerAccountViewModel,
  9
);
decorate(inject("Factory<UpdateEmailViewModel>") as any, viewModelsDashboardCustomerAccountViewModel, 10);
decorate(injectable(), viewModelsDashboardDashboardViewModel);
decorate(inject("ILocalizationService") as any, viewModelsDashboardDashboardViewModel, 0);
decorate(inject(viewModelsDashboardWidgetsSupplyPointsViewModel) as any, viewModelsDashboardDashboardViewModel, 1);
decorate(inject(servicesUserContext) as any, viewModelsDashboardDashboardViewModel, 2);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsDashboardDashboardViewModel, 3);
decorate(inject(servicesEnumsService) as any, viewModelsDashboardDashboardViewModel, 4);
decorate(inject(servicesBannersService) as any, viewModelsDashboardDashboardViewModel, 5);
decorate(inject(servicesRequestsService) as any, viewModelsDashboardDashboardViewModel, 6);
decorate(inject(repositoriesAdvancesRepository) as any, viewModelsDashboardDashboardViewModel, 7);
decorate(inject(repositoriesInvoicesRepository) as any, viewModelsDashboardDashboardViewModel, 8);
decorate(inject(viewModelsFinanceAdvanceDetailViewModel.Factory) as any, viewModelsDashboardDashboardViewModel, 9);
decorate(inject(viewModelsFinanceAdvanceChangeViewModel.Factory) as any, viewModelsDashboardDashboardViewModel, 10);
decorate(inject(viewModelsFinanceInvoiceDetailViewModel.Factory) as any, viewModelsDashboardDashboardViewModel, 11);
decorate(inject(viewModelsFinanceOnlinePaymentViewModel.Factory) as any, viewModelsDashboardDashboardViewModel, 12);
decorate(inject(repositoriesLinksRepository) as any, viewModelsDashboardDashboardViewModel, 13);
decorate(inject(servicesPortalSettingsService) as any, viewModelsDashboardDashboardViewModel, 14);
decorate(injectable(), viewModelsDashboardOverviewViewModel);
decorate(inject("ILocalizationService") as any, viewModelsDashboardOverviewViewModel, 0);
decorate(inject(viewModelsDashboardDashboardViewModel) as any, viewModelsDashboardOverviewViewModel, 1);
decorate(inject(viewModelsDashboardCustomerAccountViewModel) as any, viewModelsDashboardOverviewViewModel, 2);
decorate(inject(viewModelsDashboardPartnerConnectViewModel) as any, viewModelsDashboardOverviewViewModel, 3);
decorate(inject(viewModelsDashboardAttachmentsAttachmentsViewModel) as any, viewModelsDashboardOverviewViewModel, 4);
decorate(injectable(), viewModelsDashboardPartnerConfirmViewModel);
decorate(inject("ILocalizationService") as any, viewModelsDashboardPartnerConfirmViewModel, 0);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsDashboardPartnerConfirmViewModel, 1);
decorate(inject(repositoriesPartnersRepository) as any, viewModelsDashboardPartnerConfirmViewModel, 2);
decorate(injectable(), viewModelsDashboardPartnerConnectViewModel);
decorate(inject("ILocalizationService") as any, viewModelsDashboardPartnerConnectViewModel, 0);
decorate(inject(servicesEnumsService) as any, viewModelsDashboardPartnerConnectViewModel, 1);
decorate(inject(servicesUserContext) as any, viewModelsDashboardPartnerConnectViewModel, 2);
decorate(inject("INotificationService") as any, viewModelsDashboardPartnerConnectViewModel, 3);
decorate(inject(repositoriesPartnersRepository) as any, viewModelsDashboardPartnerConnectViewModel, 4);
decorate(injectable(), viewModelsDashboardPartnerRemoveViewModel);
decorate(inject("ILocalizationService") as any, viewModelsDashboardPartnerRemoveViewModel, 0);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsDashboardPartnerRemoveViewModel, 1);
decorate(inject(servicesDataSyncService) as any, viewModelsDashboardPartnerRemoveViewModel, 2);
decorate(inject(servicesEnumsService) as any, viewModelsDashboardPartnerRemoveViewModel, 3);
decorate(inject(viewModelsProfileStatusViewModel) as any, viewModelsDashboardPartnerRemoveViewModel, 4);
decorate(inject(repositoriesPartnersRepository) as any, viewModelsDashboardPartnerRemoveViewModel, 5);
decorate(injectable(), viewModelsFinanceAdvanceChangeViewModel);
decorate(injectable(), viewModelsFinanceAdvanceDetailViewModel);
decorate(injectable(), viewModelsFinanceAdvancesPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsFinanceAdvancesPageViewModel, 0);
decorate(inject(servicesUserContext) as any, viewModelsFinanceAdvancesPageViewModel, 1);
decorate(inject(servicesDataSyncService) as any, viewModelsFinanceAdvancesPageViewModel, 2);
decorate(inject(repositoriesAdvancesRepository) as any, viewModelsFinanceAdvancesPageViewModel, 3);
decorate(inject(servicesEnumsService) as any, viewModelsFinanceAdvancesPageViewModel, 4);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsFinanceAdvancesPageViewModel, 5);
decorate(inject(viewModelsFinanceAdvanceDetailViewModel.Factory) as any, viewModelsFinanceAdvancesPageViewModel, 6);
decorate(inject(viewModelsFinanceAdvanceChangeViewModel.Factory) as any, viewModelsFinanceAdvancesPageViewModel, 7);
decorate(injectable(), viewModelsFinanceInvoiceComplaintViewModel);
decorate(injectable(), viewModelsFinanceInvoiceDetailViewModel);
decorate(injectable(), viewModelsFinanceInvoicesPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsFinanceInvoicesPageViewModel, 0);
decorate(inject(servicesUserContext) as any, viewModelsFinanceInvoicesPageViewModel, 1);
decorate(inject(servicesDataSyncService) as any, viewModelsFinanceInvoicesPageViewModel, 2);
decorate(inject(repositoriesInvoicesRepository) as any, viewModelsFinanceInvoicesPageViewModel, 3);
decorate(inject(servicesEnumsService) as any, viewModelsFinanceInvoicesPageViewModel, 4);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsFinanceInvoicesPageViewModel, 5);
decorate(inject(viewModelsFinanceInvoiceDetailViewModel.Factory) as any, viewModelsFinanceInvoicesPageViewModel, 6);
decorate(injectable(), viewModelsFinanceOnlinePaymentViewModel);
decorate(injectable(), viewModelsFinanceOverviewViewModel);
decorate(inject("ILocalizationService") as any, viewModelsFinanceOverviewViewModel, 0);
decorate(multiInject("IFinancePage") as any, viewModelsFinanceOverviewViewModel, 1);
decorate(inject(servicesUserContext) as any, viewModelsFinanceOverviewViewModel, 2);
decorate(inject(servicesDownloadingService) as any, viewModelsFinanceOverviewViewModel, 3);
decorate(injectable(), viewModelsFinancePaymentsPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsFinancePaymentsPageViewModel, 0);
decorate(inject(servicesUserContext) as any, viewModelsFinancePaymentsPageViewModel, 1);
decorate(inject(servicesDataSyncService) as any, viewModelsFinancePaymentsPageViewModel, 2);
decorate(inject(repositoriesPaymentsRepository) as any, viewModelsFinancePaymentsPageViewModel, 3);
decorate(inject(servicesEnumsService) as any, viewModelsFinancePaymentsPageViewModel, 4);
decorate(injectable(), viewModelsMessagesOverviewViewModel);
decorate(inject("ILocalizationService") as any, viewModelsMessagesOverviewViewModel, 0);
decorate(inject(servicesMessagesService) as any, viewModelsMessagesOverviewViewModel, 1);
decorate(inject(servicesUserContext) as any, viewModelsMessagesOverviewViewModel, 2);
decorate(inject(repositoriesMessagesRepository) as any, viewModelsMessagesOverviewViewModel, 3);
decorate(injectable(), viewModelsProductsAppendixesPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProductsAppendixesPageViewModel, 0);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsProductsAppendixesPageViewModel, 1);
decorate(inject(servicesEnumsService) as any, viewModelsProductsAppendixesPageViewModel, 2);
decorate(inject(servicesDataSyncService) as any, viewModelsProductsAppendixesPageViewModel, 3);
decorate(inject(servicesAppendixesService) as any, viewModelsProductsAppendixesPageViewModel, 4);
decorate(injectable(), viewModelsProductsAxaPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProductsAxaPageViewModel, 0);
decorate(inject(servicesProductCardsService) as any, viewModelsProductsAxaPageViewModel, 1);
decorate(inject("IEventBus") as any, viewModelsProductsAxaPageViewModel, 2);
decorate(injectable(), viewModelsProductsCngPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProductsCngPageViewModel, 0);
decorate(inject(servicesProductCardsService) as any, viewModelsProductsCngPageViewModel, 1);
decorate(inject("IEventBus") as any, viewModelsProductsCngPageViewModel, 2);
decorate(injectable(), viewModelsProductsCustomerCardPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProductsCustomerCardPageViewModel, 0);
decorate(injectable(), viewModelsProductsOtherProductsPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProductsOtherProductsPageViewModel, 0);
decorate(inject(servicesProductCardsService) as any, viewModelsProductsOtherProductsPageViewModel, 1);
decorate(inject("IEventBus") as any, viewModelsProductsOtherProductsPageViewModel, 2);
decorate(injectable(), viewModelsProductsOverviewViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProductsOverviewViewModel, 0);
decorate(multiInject("IProductPage") as any, viewModelsProductsOverviewViewModel, 1);
decorate(inject(servicesUserContext) as any, viewModelsProductsOverviewViewModel, 2);
decorate(inject(servicesEnumsService) as any, viewModelsProductsOverviewViewModel, 3);
decorate(inject(servicesAppendixesService) as any, viewModelsProductsOverviewViewModel, 4);
decorate(inject("IEventBus") as any, viewModelsProductsOverviewViewModel, 5);
decorate(injectable(), viewModelsProfileCompetitionsViewModel);
decorate(inject(servicesUserContext) as any, viewModelsProfileCompetitionsViewModel, 0);
decorate(inject(repositoriesPartnersRepository) as any, viewModelsProfileCompetitionsViewModel, 1);
decorate(inject("ILocalizationService") as any, viewModelsProfileCompetitionsViewModel, 2);
decorate(inject("INotificationService") as any, viewModelsProfileCompetitionsViewModel, 3);
decorate(injectable(), viewModelsProfileConfirmRegistrationViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProfileConfirmRegistrationViewModel, 0);
decorate(inject(servicesValidationPasswordValidationService) as any, viewModelsProfileConfirmRegistrationViewModel, 1);
decorate(inject(repositoriesLoginRepository) as any, viewModelsProfileConfirmRegistrationViewModel, 2);
decorate(inject(servicesSecurityService) as any, viewModelsProfileConfirmRegistrationViewModel, 3);
decorate(inject(repositoriesPartnersRepository) as any, viewModelsProfileConfirmRegistrationViewModel, 4);
decorate(inject(servicesEnumsService) as any, viewModelsProfileConfirmRegistrationViewModel, 5);
decorate(injectable(), viewModelsProfileForgottenPasswordViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProfileForgottenPasswordViewModel, 0);
decorate(inject(repositoriesLoginRepository) as any, viewModelsProfileForgottenPasswordViewModel, 1);
decorate(injectable(), viewModelsProfileLoginViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProfileLoginViewModel, 0);
decorate(inject(servicesSecurityService) as any, viewModelsProfileLoginViewModel, 1);
decorate(inject(servicesPortalSettingsService) as any, viewModelsProfileLoginViewModel, 2);
decorate(injectable(), viewModelsProfileRefreshCredentialsViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProfileRefreshCredentialsViewModel, 0);
decorate(inject(servicesUserContext) as any, viewModelsProfileRefreshCredentialsViewModel, 1);
decorate(inject(servicesSecurityService) as any, viewModelsProfileRefreshCredentialsViewModel, 2);
decorate(injectable(), viewModelsProfileRegistrationViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProfileRegistrationViewModel, 0);
decorate(inject(repositoriesLoginRepository) as any, viewModelsProfileRegistrationViewModel, 1);
decorate(inject("INotificationService") as any, viewModelsProfileRegistrationViewModel, 2);
decorate(injectable(), viewModelsProfileResetPasswordViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProfileResetPasswordViewModel, 0);
decorate(inject(servicesValidationPasswordValidationService) as any, viewModelsProfileResetPasswordViewModel, 1);
decorate(inject(repositoriesLoginRepository) as any, viewModelsProfileResetPasswordViewModel, 2);
decorate(inject(servicesSecurityService) as any, viewModelsProfileResetPasswordViewModel, 3);
decorate(injectable(), viewModelsProfileStatusViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProfileStatusViewModel, 0);
decorate(inject(servicesSecurityService) as any, viewModelsProfileStatusViewModel, 1);
decorate(inject(servicesUserContext) as any, viewModelsProfileStatusViewModel, 2);
decorate(injectable(), viewModelsProfileUpdateEmailViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProfileUpdateEmailViewModel, 0);
decorate(inject(repositoriesLoginRepository) as any, viewModelsProfileUpdateEmailViewModel, 1);
decorate(inject(servicesUserContext) as any, viewModelsProfileUpdateEmailViewModel, 2);
decorate(injectable(), viewModelsProfileUpdatePasswordViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProfileUpdatePasswordViewModel, 0);
decorate(inject(servicesValidationPasswordValidationService) as any, viewModelsProfileUpdatePasswordViewModel, 1);
decorate(inject(repositoriesLoginRepository) as any, viewModelsProfileUpdatePasswordViewModel, 2);
decorate(inject("INotificationService") as any, viewModelsProfileUpdatePasswordViewModel, 3);
decorate(inject(servicesUserContext) as any, viewModelsProfileUpdatePasswordViewModel, 4);
decorate(injectable(), viewModelsRequestsModalCreateRequestViewModel);
decorate(injectable(), viewModelsRequestsOverviewViewModel);
decorate(inject("ILocalizationService") as any, viewModelsRequestsOverviewViewModel, 0);
decorate(inject(servicesRequestsService) as any, viewModelsRequestsOverviewViewModel, 1);
decorate(inject(servicesUserContext) as any, viewModelsRequestsOverviewViewModel, 2);
decorate(inject(servicesEnumsService) as any, viewModelsRequestsOverviewViewModel, 3);
decorate(inject(servicesDataSyncService) as any, viewModelsRequestsOverviewViewModel, 4);
decorate(inject(viewModelsRequestsModalCreateRequestViewModel.Factory) as any, viewModelsRequestsOverviewViewModel, 5);
decorate(inject(repositoriesRequestsRepository) as any, viewModelsRequestsOverviewViewModel, 6);
decorate(inject("IEventBus") as any, viewModelsRequestsOverviewViewModel, 7);
decorate(injectable(), viewModelsSupplyPointsOverviewViewModel);
decorate(inject("ILocalizationService") as any, viewModelsSupplyPointsOverviewViewModel, 0);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsSupplyPointsOverviewViewModel, 1);
decorate(inject(viewModelsProductsMyProductsProductDetailViewModel.Factory) as any, viewModelsSupplyPointsOverviewViewModel, 2);
decorate(inject(servicesUserContext) as any, viewModelsSupplyPointsOverviewViewModel, 3);
decorate(inject(servicesDataSyncService) as any, viewModelsSupplyPointsOverviewViewModel, 4);
decorate(inject(servicesEnumsService) as any, viewModelsSupplyPointsOverviewViewModel, 5);
decorate(inject(servicesBannersService) as any, viewModelsSupplyPointsOverviewViewModel, 6);
decorate(inject(servicesHistoricalSupplyPointsService) as any, viewModelsSupplyPointsOverviewViewModel, 7);
decorate(injectable(), viewModelsAdminProductCardsProductCardDetailViewModel);
decorate(injectable(), viewModelsAdminProductCardsProductCardPreviewViewModel);
decorate(injectable(), viewModelsAdminProductCardsProductCardsPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsAdminProductCardsProductCardsPageViewModel, 0);
decorate(
  inject(viewModelsAdminProductCardsProductCardDetailViewModel.Factory) as any,
  viewModelsAdminProductCardsProductCardsPageViewModel,
  1
);
decorate(
  inject(viewModelsAdminProductCardsProductCardPreviewViewModel.Factory) as any,
  viewModelsAdminProductCardsProductCardsPageViewModel,
  2
);
decorate(inject(repositoriesAdminRepository) as any, viewModelsAdminProductCardsProductCardsPageViewModel, 3);
decorate(inject(servicesConfirmationService) as any, viewModelsAdminProductCardsProductCardsPageViewModel, 4);
decorate(inject("INotificationService") as any, viewModelsAdminProductCardsProductCardsPageViewModel, 5);
decorate(injectable(), viewModelsDashboardAttachmentsAttachmentCreateViewModel);
decorate(injectable(), viewModelsDashboardAttachmentsAttachmentsViewModel);
decorate(inject("ILocalizationService") as any, viewModelsDashboardAttachmentsAttachmentsViewModel, 0);
decorate(inject(servicesUserContext) as any, viewModelsDashboardAttachmentsAttachmentsViewModel, 1);
decorate(inject("INotificationService") as any, viewModelsDashboardAttachmentsAttachmentsViewModel, 2);
decorate(inject(repositoriesAttachmentsRepository) as any, viewModelsDashboardAttachmentsAttachmentsViewModel, 3);
decorate(inject(servicesConfirmationService) as any, viewModelsDashboardAttachmentsAttachmentsViewModel, 4);
decorate(
  inject(viewModelsDashboardAttachmentsAttachmentUpdateViewModel.Factory) as any,
  viewModelsDashboardAttachmentsAttachmentsViewModel,
  5
);
decorate(
  inject(viewModelsDashboardAttachmentsAttachmentCreateViewModel.Factory) as any,
  viewModelsDashboardAttachmentsAttachmentsViewModel,
  6
);
decorate(injectable(), viewModelsDashboardAttachmentsAttachmentUpdateViewModel);
decorate(injectable(), viewModelsDashboardWidgetsSupplyPointsViewModel);
decorate(inject("ILocalizationService") as any, viewModelsDashboardWidgetsSupplyPointsViewModel, 0);
decorate(inject(nodeModulesFruiTsScreensDistNavigationRouter) as any, viewModelsDashboardWidgetsSupplyPointsViewModel, 1);
decorate(inject(servicesUserContext) as any, viewModelsDashboardWidgetsSupplyPointsViewModel, 2);
decorate(inject(servicesDataSyncService) as any, viewModelsDashboardWidgetsSupplyPointsViewModel, 3);
decorate(inject(servicesEnumsService) as any, viewModelsDashboardWidgetsSupplyPointsViewModel, 4);
decorate(inject(servicesHistoricalSupplyPointsService) as any, viewModelsDashboardWidgetsSupplyPointsViewModel, 5);
decorate(injectable(), viewModelsProductsMyProductsProductDetailViewModel);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesAdvancesDetailPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProductsMyProductsDetailPagesAdvancesDetailPageViewModel, 0);
decorate(inject(servicesUserContext) as any, viewModelsProductsMyProductsDetailPagesAdvancesDetailPageViewModel, 1);
decorate(inject(servicesDataSyncService) as any, viewModelsProductsMyProductsDetailPagesAdvancesDetailPageViewModel, 2);
decorate(inject(repositoriesAdvancesRepository) as any, viewModelsProductsMyProductsDetailPagesAdvancesDetailPageViewModel, 3);
decorate(inject(servicesEnumsService) as any, viewModelsProductsMyProductsDetailPagesAdvancesDetailPageViewModel, 4);
decorate(
  inject(nodeModulesFruiTsScreensDistNavigationRouter) as any,
  viewModelsProductsMyProductsDetailPagesAdvancesDetailPageViewModel,
  5
);
decorate(
  inject(viewModelsFinanceAdvanceDetailViewModel.Factory) as any,
  viewModelsProductsMyProductsDetailPagesAdvancesDetailPageViewModel,
  6
);
decorate(
  inject(viewModelsFinanceAdvanceChangeViewModel.Factory) as any,
  viewModelsProductsMyProductsDetailPagesAdvancesDetailPageViewModel,
  7
);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesInvoicesDetailPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProductsMyProductsDetailPagesInvoicesDetailPageViewModel, 0);
decorate(inject(servicesUserContext) as any, viewModelsProductsMyProductsDetailPagesInvoicesDetailPageViewModel, 1);
decorate(inject(servicesDataSyncService) as any, viewModelsProductsMyProductsDetailPagesInvoicesDetailPageViewModel, 2);
decorate(inject(repositoriesInvoicesRepository) as any, viewModelsProductsMyProductsDetailPagesInvoicesDetailPageViewModel, 3);
decorate(inject(servicesEnumsService) as any, viewModelsProductsMyProductsDetailPagesInvoicesDetailPageViewModel, 4);
decorate(
  inject(nodeModulesFruiTsScreensDistNavigationRouter) as any,
  viewModelsProductsMyProductsDetailPagesInvoicesDetailPageViewModel,
  5
);
decorate(
  inject(viewModelsFinanceInvoiceDetailViewModel.Factory) as any,
  viewModelsProductsMyProductsDetailPagesInvoicesDetailPageViewModel,
  6
);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesPaymentsDetailPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProductsMyProductsDetailPagesPaymentsDetailPageViewModel, 0);
decorate(inject(servicesUserContext) as any, viewModelsProductsMyProductsDetailPagesPaymentsDetailPageViewModel, 1);
decorate(inject(servicesDataSyncService) as any, viewModelsProductsMyProductsDetailPagesPaymentsDetailPageViewModel, 2);
decorate(inject(repositoriesPaymentsRepository) as any, viewModelsProductsMyProductsDetailPagesPaymentsDetailPageViewModel, 3);
decorate(inject(servicesEnumsService) as any, viewModelsProductsMyProductsDetailPagesPaymentsDetailPageViewModel, 4);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesContractContractDetailPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProductsMyProductsDetailPagesContractContractDetailPageViewModel, 0);
decorate(
  inject(repositoriesContractRepository) as any,
  viewModelsProductsMyProductsDetailPagesContractContractDetailPageViewModel,
  1
);
decorate(inject(servicesAppendixesService) as any, viewModelsProductsMyProductsDetailPagesContractContractDetailPageViewModel, 2);
decorate(inject(servicesUserContext) as any, viewModelsProductsMyProductsDetailPagesContractContractDetailPageViewModel, 3);
decorate(inject("IEventBus") as any, viewModelsProductsMyProductsDetailPagesContractContractDetailPageViewModel, 4);
decorate(
  inject(viewModelsProductsMyProductsDetailPagesContractModalDocumentsViewModel.Factory) as any,
  viewModelsProductsMyProductsDetailPagesContractContractDetailPageViewModel,
  5
);
decorate(
  inject(servicesHistoricalSupplyPointsService) as any,
  viewModelsProductsMyProductsDetailPagesContractContractDetailPageViewModel,
  6
);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesContractModalDocumentsViewModel);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesCustomerInfoCustomerInfoDetailPageViewModel);
decorate(
  inject("ILocalizationService") as any,
  viewModelsProductsMyProductsDetailPagesCustomerInfoCustomerInfoDetailPageViewModel,
  0
);
decorate(
  inject(servicesUserContext) as any,
  viewModelsProductsMyProductsDetailPagesCustomerInfoCustomerInfoDetailPageViewModel,
  1
);
decorate(
  inject(viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditCustomerInfoViewModel.Factory) as any,
  viewModelsProductsMyProductsDetailPagesCustomerInfoCustomerInfoDetailPageViewModel,
  2
);
decorate(
  inject(viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditContactInfoViewModel.Factory) as any,
  viewModelsProductsMyProductsDetailPagesCustomerInfoCustomerInfoDetailPageViewModel,
  3
);
decorate(
  inject(viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditMarketingAgreementViewModel.Factory) as any,
  viewModelsProductsMyProductsDetailPagesCustomerInfoCustomerInfoDetailPageViewModel,
  4
);
decorate(
  inject(viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditMailingAddressViewModel.Factory) as any,
  viewModelsProductsMyProductsDetailPagesCustomerInfoCustomerInfoDetailPageViewModel,
  5
);
decorate(
  inject(servicesEnumsService) as any,
  viewModelsProductsMyProductsDetailPagesCustomerInfoCustomerInfoDetailPageViewModel,
  6
);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditContactInfoViewModel);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditCustomerInfoViewModel);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditHistoricalSupplyPoints);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditMailingAddressViewModel);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesCustomerInfoModalEditMarketingAgreementViewModel);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesHistoryBulkSelfReadingViewModel);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesHistoryChartViewModel);
decorate(inject(modelsProductDetailContext) as any, viewModelsProductsMyProductsDetailPagesHistoryChartViewModel, 0);
decorate(inject(repositoriesReadingsRepository) as any, viewModelsProductsMyProductsDetailPagesHistoryChartViewModel, 1);
decorate(inject("ILocalizationService") as any, viewModelsProductsMyProductsDetailPagesHistoryChartViewModel, 2);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesHistoryHistoryDetailPageViewModel);
decorate(inject("ILocalizationService") as any, viewModelsProductsMyProductsDetailPagesHistoryHistoryDetailPageViewModel, 0);
decorate(
  inject(repositoriesReadingsRepository) as any,
  viewModelsProductsMyProductsDetailPagesHistoryHistoryDetailPageViewModel,
  1
);
decorate(
  inject(viewModelsProductsMyProductsDetailPagesHistoryModalSelfReadingViewModel.Factory) as any,
  viewModelsProductsMyProductsDetailPagesHistoryHistoryDetailPageViewModel,
  2
);
decorate(inject(servicesEnumsService) as any, viewModelsProductsMyProductsDetailPagesHistoryHistoryDetailPageViewModel, 3);
decorate(inject("IEventBus") as any, viewModelsProductsMyProductsDetailPagesHistoryHistoryDetailPageViewModel, 4);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesHistoryModalSelfReadingViewModel);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesHistorySingleSelfReadingViewModel);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesHistoryTableViewModel);
decorate(inject(modelsProductDetailContext) as any, viewModelsProductsMyProductsDetailPagesHistoryTableViewModel, 0);
decorate(inject(repositoriesReadingsRepository) as any, viewModelsProductsMyProductsDetailPagesHistoryTableViewModel, 1);
decorate(inject(servicesEnumsService) as any, viewModelsProductsMyProductsDetailPagesHistoryTableViewModel, 2);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesOptionsDepositModalAdvancePaymentMethodViewModel);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesOptionsDepositModalInvoiceMailingMethodViewModel);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesOptionsDepositModalInvoicePaymentMethodViewModel);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesOptionsDepositOptionsDepositDetailPageViewModel);
decorate(
  inject("ILocalizationService") as any,
  viewModelsProductsMyProductsDetailPagesOptionsDepositOptionsDepositDetailPageViewModel,
  0
);
decorate(
  inject(servicesEnumsService) as any,
  viewModelsProductsMyProductsDetailPagesOptionsDepositOptionsDepositDetailPageViewModel,
  1
);
decorate(
  inject(viewModelsProductsMyProductsDetailPagesOptionsDepositModalInvoiceMailingMethodViewModel.Factory) as any,
  viewModelsProductsMyProductsDetailPagesOptionsDepositOptionsDepositDetailPageViewModel,
  2
);
decorate(
  inject(viewModelsProductsMyProductsDetailPagesOptionsDepositModalInvoicePaymentMethodViewModel.Factory) as any,
  viewModelsProductsMyProductsDetailPagesOptionsDepositOptionsDepositDetailPageViewModel,
  3
);
decorate(
  inject(viewModelsProductsMyProductsDetailPagesOptionsDepositModalAdvancePaymentMethodViewModel.Factory) as any,
  viewModelsProductsMyProductsDetailPagesOptionsDepositOptionsDepositDetailPageViewModel,
  4
);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesSupplyPointCustomerTransferViewModel);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesSupplyPointModalEditSupplyPointViewModel);
decorate(injectable(), viewModelsProductsMyProductsDetailPagesSupplyPointSupplyPointDetailPageViewModel);
decorate(
  inject("ILocalizationService") as any,
  viewModelsProductsMyProductsDetailPagesSupplyPointSupplyPointDetailPageViewModel,
  0
);
decorate(
  inject(servicesEnumsService) as any,
  viewModelsProductsMyProductsDetailPagesSupplyPointSupplyPointDetailPageViewModel,
  1
);
decorate(
  inject(viewModelsProductsMyProductsDetailPagesSupplyPointModalEditSupplyPointViewModel.Factory) as any,
  viewModelsProductsMyProductsDetailPagesSupplyPointSupplyPointDetailPageViewModel,
  2
);
decorate(
  inject(viewModelsProductsMyProductsDetailPagesSupplyPointCustomerTransferViewModel.Factory) as any,
  viewModelsProductsMyProductsDetailPagesSupplyPointSupplyPointDetailPageViewModel,
  3
);
decorate(
  inject(repositoriesReadingsRepository) as any,
  viewModelsProductsMyProductsDetailPagesSupplyPointSupplyPointDetailPageViewModel,
  4
);
decorate(
  inject(repositoriesContractRepository) as any,
  viewModelsProductsMyProductsDetailPagesSupplyPointSupplyPointDetailPageViewModel,
  5
);
decorate(
  inject(nodeModulesFruiTsScreensDistNavigationRouter) as any,
  viewModelsProductsMyProductsDetailPagesSupplyPointSupplyPointDetailPageViewModel,
  6
);
decorate(inject("IEventBus") as any, viewModelsProductsMyProductsDetailPagesSupplyPointSupplyPointDetailPageViewModel, 7);
decorate(
  inject(repositoriesLinksRepository) as any,
  viewModelsProductsMyProductsDetailPagesSupplyPointSupplyPointDetailPageViewModel,
  8
);
decorate(
  inject(servicesHistoricalSupplyPointsService) as any,
  viewModelsProductsMyProductsDetailPagesSupplyPointSupplyPointDetailPageViewModel,
  9
);
