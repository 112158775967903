import React from "react";
import { styleBlock } from "..";
import iconCheck from "../assets/icons/Check.svg";

export interface SupplyPointLike {
  id: number;
  title?: string;
  address: string;
  icon: string;
  accountId: number;
  error?: string;
  contractExternalId?: string;
  accountExternalId?: string;
}

export interface SupplyPointListItemProps {
  className?: string;
  selected?: boolean;
  data: SupplyPointLike;
  onClick?: any;
  blue?: boolean;
}

const style = styleBlock("supply-point-list--item");

const SupplyPointListItem: React.FunctionComponent<SupplyPointListItemProps> = ({ data, selected, onClick, blue, className }) => {
  return (
    <>
      <div className={style.mix(selected && "selected", className, blue && "blue")} onClick={onClick}>
        <img className={style("icon")} width={34} height={34} src={data.icon} />
        <div className={style("inner")}>
          <div className={style("title")}>{data.title}</div>
          <div className={style("address")}>{data.address}</div>
        </div>
        <img className={style("check")} width={28} height={28} src={iconCheck} />
      </div>
      {data.error && <div className={style("error")}>{data.error}</div>}
    </>
  );
};

export default SupplyPointListItem;
