import { Button, ProductRow } from "@emanprague/ppaskit";

import type { ILocalizationService } from "@emanprague/shared-services";
import type { Router } from "@frui.ts/screens";
import { observer } from "mobx-react-lite";
import CommodityType from "models/commodityType";
import React from "react";
import { getSupplyPointTypeIcon } from "helpers/supplyPointHelper";
import iconArrowRightGray from "@emanprague/ppaskit/dist/assets/icons/Arrow/Right.svg";
import OverviewViewModel from "viewModels/supplyPoints/overviewViewModel";
import type SupplyPointListItem from "entities/supplyPointListItem";
import { SupplyPointRow } from "./supplyPointRow";

interface SupplyPointsListProps {
  localization: ILocalizationService;
  supplyPointsByType: Map<CommodityType, SupplyPointListItem[]>;
  router: Router;
  limitOfDisplayedSupplyPoints?: number;
  onToggle: (supplyPoint: SupplyPointListItem) => void;
}

const SupplyPointsList: React.FunctionComponent<SupplyPointsListProps> = observer(
  ({ localization, supplyPointsByType, router, limitOfDisplayedSupplyPoints, onToggle }) => {
    const { translateGeneral: tg, translateModel: tm } = localization;
    const translate = (code: string) => tg(`dashboard.supply_points.${code}`);
    const translateGeneral = (code: string) => tg(`general.${code}`);

    let gasSupplyPoints = supplyPointsByType.get(CommodityType.Gas);
    if (gasSupplyPoints) {
      gasSupplyPoints = [
        ...gasSupplyPoints.filter(item => item.contract.active),
        ...gasSupplyPoints.filter(item => !item.contract.active),
      ];
    }

    let electricitySupplyPoints = supplyPointsByType.get(CommodityType.Electricity);
    if (electricitySupplyPoints) {
      electricitySupplyPoints = [
        ...electricitySupplyPoints.filter(item => item.contract.active),
        ...electricitySupplyPoints.filter(item => !item.contract.active),
      ];
    }

    if (limitOfDisplayedSupplyPoints) {
      gasSupplyPoints = gasSupplyPoints?.slice(0, limitOfDisplayedSupplyPoints);
      electricitySupplyPoints = electricitySupplyPoints?.slice(0, limitOfDisplayedSupplyPoints);
    }

    const electricityDisplayAllButton = (className: string) => (
      <>
        {limitOfDisplayedSupplyPoints &&
          supplyPointsByType.get(CommodityType.Electricity)!.length > limitOfDisplayedSupplyPoints && (
            <Button
              variant="link"
              className={"font-size-base font-weight-bold link-underline-hover " + className}
              href={router.getUrl(OverviewViewModel)}>
              <span className="d-flex align-items-center">
                {translateGeneral("display_all_button")}
                <img className="ml-2 opacity-third" width="15" height="12" draggable={false} src={iconArrowRightGray} alt="" />
              </span>
            </Button>
          )}
      </>
    );

    const supplyPointRows = (supplyPoints: SupplyPointListItem[]) => (
      <>
        {supplyPoints.map(supplyPoint => (
          <SupplyPointRow
            key={supplyPoint.id}
            supplyPoint={supplyPoint}
            localization={localization}
            router={router}
            onToggle={onToggle}
          />
        ))}
      </>
    );

    const gasDisplayAllButton = (className: string) => (
      <>
        {limitOfDisplayedSupplyPoints && supplyPointsByType.get(CommodityType.Gas)!.length > limitOfDisplayedSupplyPoints && (
          <Button
            variant="link"
            className={"font-size-base font-weight-bold link-underline-hover " + className}
            href={router.getUrl(OverviewViewModel)}>
            {translateGeneral("display_all_button")}
            <img className="ml-2 opacity-third" width="15" height="12" draggable={false} src={iconArrowRightGray} alt="" />
          </Button>
        )}
      </>
    );

    return (
      <>
        {gasSupplyPoints && (
          <div className="pb-4">
            <ProductRow
              id="pr-1"
              icon={getSupplyPointTypeIcon(CommodityType.Gas, "yellowAndLightBlue")}
              title={translate("gas")}
              titleSuffix={tm("supply_point", 3)}
              titleDisabled={!supplyPointsByType.get(CommodityType.Gas)}
              className="my-4 mt-sm-5"
              actions={<div className="d-flex align-items-center">{gasDisplayAllButton("pr-0 d-none d-sm-block")}</div>}
            />
            {supplyPointRows(gasSupplyPoints)}
            {gasDisplayAllButton("d-flex justify-content-center d-sm-none")}
          </div>
        )}
        {electricitySupplyPoints && (
          <div className="pb-4">
            <ProductRow
              id="pr-1"
              icon={getSupplyPointTypeIcon(CommodityType.Electricity, "yellowAndLightBlue")}
              title={translate("electricity")}
              titleSuffix={tm("supply_point", 3)}
              titleDisabled={!supplyPointsByType.get(CommodityType.Electricity)}
              className="my-4 mt-sm-5"
              actions={<div className="d-flex align-items-center">{electricityDisplayAllButton("pr-0 d-none d-sm-block")}</div>}
            />
            {supplyPointRows(electricitySupplyPoints)}
            {electricityDisplayAllButton("d-flex justify-content-center d-sm-none")}
          </div>
        )}
      </>
    );
  }
);

export default SupplyPointsList;
